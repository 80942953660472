import React, { useEffect, useState, useCallback } from "react";
import { DatePicker, Dropdown, TextField, Checkbox, MaskedTextField, Stack, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { authProvider } from '../authProvider';
import axios from "axios";

const rootUrl = window.location.origin + "/api/employeeforms/";
const stackTokens = { childrenGap: 10 };

const dropdownOptions = [
    { key: "Picking Up", text: "Picking Up" },
    { key: "Dropping Off", text: "Dropping Off" }
];

const baseErrors = {
    employeeName: "",
    teamLead: "",
    terminationDate: "",
    redirectEmailTo: "",
    redirectPhoneTo: "",
    backupEmail: "",
    backupOneDrive: "",
    equipmentRecovery: "",
    recoveryDate: "",
    recoveryMethod: "",
    recoveryDetails: "",
    retrieveBuildingKey: ""
}

const baseTermRequest = {
    employeeName: "",
    teamLead: "",
    terminationDate: "",
    redirectEmailTo: "",
    redirectPhoneTo: "",
    backupEmail: false,
    backupOneDrive: false,
    equipmentRecovery: false,
    recoveryDate: "",
    recoveryMethod: "",
    recoveryDetails: "",
    retrieveBuildingKey: false,
    connectWise: false,
    knowBe4: false,
    yammer: false,
    sharePoint: false,
    itGlue: false,
    quickBooks: false
}

// Format Date as MM/DD/YYYY
const onFormatDate = (date) => {
    return !date ? '' : (date.getMonth() + 1) + '/' + (date.getDate()) + '/' + (date.getFullYear());
};

export const TermEmployeeForm = (params) => {

    const [termRequest, setTermRequest] = useState({ ...baseTermRequest });
    const [errors, setErrors] = useState({ ...baseErrors })
    const [termDate, setTermDate] = useState();
    const [recoveryDate, setRecoveryDate] = useState();
    const [token, setToken] = useState();
    const [hasAccess, setHasAccess] = useState(false);
    const [messageBarShown, setMessageBarShown] = useState(false);
    const [messageBarText, setMessageBarText] = useState("");
    const [messageBarType, setMessageBarType] = useState(MessageBarType.success);
    const [teamLeaders, setTeamLeaders] = useState([]);

    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(token => tkn);
                axios.get(rootUrl + "TeamLeaders", { headers: { "Authorization": "Bearer " + token } }).then((result) => {
                    setTeamLeaders(result.data.map((tl) => { return { key: tl.id, text: tl.name } }));
                });
            }
        });
        authProvider.getIdToken().then((result) => {
            if (result.idToken.claims.roles.includes("Submit.EmployeeForm")) {
                setHasAccess(ha => true);
            }
        });
    }, []);


    const onParseDateFromString = useCallback(
        (newValue) => {
            const value = termRequest.startDate
            const previousValue = value || new Date();
            const newValueParts = (newValue || '').trim().split('/');
            const day =
                newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[1], 10))) : previousValue.getDate();
            const month =
                newValueParts.length > 1
                    ? Math.max(1, Math.min(12, parseInt(newValueParts[0], 10))) - 1
                    : previousValue.getMonth();
            let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
            if (year < 100) {
                year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
            }
            return new Date(year, month, day);
        },
        [termRequest],
    );
    // Handle Text field updates    
    const setField = useCallback((fieldName, evt) => {
        setTermRequest((req) => {
            const nReq = { ...req }
            nReq[fieldName] = evt.target.value;
            return nReq;
        });
    }, [setTermRequest]);

    const onNameChange = useCallback((evt) => {
        setField("employeeName", evt);
    }, [setField]);
    const onTeamLeadChange = useCallback((evt) => {
        setField("teamLead", evt);
    }, [setField]);
    const onRedirectPhoneToChange = useCallback((evt) => {
        setField("redirectPhoneTo", evt);
    }, [setField]);
    const onRedirectEmailToChange = useCallback((evt) => {
        setField("redirectEmailTo", evt);
    }, [setField]);
    const onRecoveryDetailsChange = useCallback((evt) => {
        setField("recoveryDetails", evt);
    }, [setField]);

    // Handle Date changes
    const onTerminationDateChange = useCallback((date) => {
        setTermRequest((req) => {
            const nReq = { ...req };
            nReq.terminationDate = onFormatDate(date);
            return nReq;
        })
        setTermDate(date);
    }, [setTermRequest, setTermDate]);
    const onRecoveryDateChange = useCallback((date) => {
        setTermRequest((req) => {
            const nReq = { ...req };
            nReq.recoveryDate = onFormatDate(date);
            return nReq;
        })
        setRecoveryDate(date);
    }, [setTermRequest, setRecoveryDate]);

    // Handle DropDown changes
    const onRecoveryMethodChange = useCallback((evt) => {
        setField("recoveryMethod", evt);
    }, [setField]);

    // Handle checkbox changes
    const setCheckbox = useCallback((fieldName, checked) => {
        setTermRequest((req) => {
            const nReq = { ...req };
            nReq[fieldName] = checked;
            console.log("Set " + fieldName + " to " + checked);
            return nReq;
        });

    }, [setTermRequest]);

    const onBackupEmailChange = useCallback((ev, checked) => {
        setCheckbox("backupEmail", checked);
    }, [setCheckbox]);
    const onBackupOneDriveChange = useCallback((ev, checked) => {
        setCheckbox("backupOneDrive", checked);
    }, [setCheckbox]);
    const onEquipmentRecoveryChange = useCallback((ev, checked) => {
        setCheckbox("equipmentRecovery", checked);
    }, [setCheckbox]);

    const onConnectWiseChange = useCallback((ev, checked) => {
        setCheckbox("connectWise", checked);
    });

    const onKnowBe4Change = useCallback((ev, checked) => {
        setCheckbox("knowBe4", checked);
    });

    const onYammerChange = useCallback((ev, checked) => {
        setCheckbox("yammer", checked);
    });

    const onSharePointChange = useCallback((ev, checked) => {
        setCheckbox("sharePoint", checked);
    });

    const onItGlueChange = useCallback((ev, checked) => {
        setCheckbox("itGlue", checked);
    });

    const onQuickBooksChange = useCallback((ev, checked) => {
        setCheckbox("quickBooks", checked);
    });

    const onRetrieveBuildingKeyChange = useCallback((ev, checked) => {
        setCheckbox("retrieveBuildingKey", checked);
    });
    const setDropdown = useCallback((fieldName, value) => {
        setTermRequest((req) => {
            const nReq = { ...req };
            nReq[fieldName] = value;
            return nReq;

        });
    }, [setTermRequest]);

    const onTeamLeaderChange = useCallback((evt, option) => {
        setDropdown("teamLead", option.key);
    }, [setDropdown]);

    // Validation
    const setError = useCallback((fName, message) => {
        setErrors((e) => {
            var nState = { ...e };
            nState[fName] = message;
            return nState;
        });

    }, [setErrors]);

    // const validateNotEmpty = useCallback((fieldName, message) => {
    //     var value = termRequest[fieldName];
    //     console.log("In ValidateNotEmpty - termRequest:");
    //     console.log(termRequest);
    //     var result = value && value.length > 0;
    //     if (!result) {
    //         if (!message) {
    //             message = fieldName + " must have a value."
    //         }
    //         setError(fieldName, message)
    //     }
    //     return result
    // }, [termRequest, setError]);

    const validateNotEmpty = useCallback((fieldName, message) => {
        var value = termRequest[fieldName];
        console.log("In ValidateNotEmpty - termRequest:");
        console.log(termRequest);
        var result = value && value.length > 0;
        if (!result) {
            if (!message) {
                message = fieldName + " must have a value."
            }
            setError(fieldName, message)
        }
        return result
    }, [termRequest, setError]);

    const validateEmployeeName = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("employeeName", "Employee name is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("employeeName", "");
        }

        return result;
    }, [setError, validateNotEmpty]);


    const validateTeamLead = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("teamLead", "Team lead is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("teamLead", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateRedirectEmail = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("redirectEmailTo", "\"Redirect Email To\" is required! Enter \"none\" if no redirection is desired.")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("redirectEmailTo", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateRedirectPhone = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("redirectPhoneTo", "\"Redirect Phone To\" is required! Enter \"none\" if no redirection is desired.")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("redirectPhoneTo", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateTerminationDate = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("terminationDate", "Termination Date is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("terminationDate", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateRecoveryDate = useCallback(() => {
        var result = true;
        // only required if equipmentrecovery is checked.
        if (!termRequest.equipmentRecovery) {
            setError("recoveryDate", "");
            return result;
        }
        if (!validateNotEmpty("recoveryDate", "Recovery Date is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("recoveryDate", "");
        }

        return result;
    }, [setError, validateNotEmpty, termRequest]);

    const validateAllFields = useCallback(() => {
        var result = true;
        if (!validateEmployeeName()) {
            result = false;
        }
        if (!validateTeamLead()) {
            result = false;
        }
        if (!validateRedirectEmail()) {
            result = false;
        }
        if (!validateRedirectPhone()) {
            result = false;
        }
        if (!validateRecoveryDate()) {
            result = false;
        }
        if (!validateTerminationDate()) {
            result = false;
        }
        return result;
    }, [validateEmployeeName, validateTeamLead, validateRedirectEmail, validateRedirectPhone, validateRecoveryDate, validateTerminationDate]);
   
    const setMbSuccess = useCallback((message) => {
        setMessageBarText(message);
        setMessageBarShown(true);
        setMessageBarType(MessageBarType.success);
    }, [setMessageBarText, setMessageBarShown, setMessageBarType]);
    
    // Submit the form.
    const onSubmitForm = useCallback(() => {
        if (validateAllFields()) {
            console.log("Submitting Form");
            axios.post(rootUrl + "TerminateEmployee", termRequest, { headers: { "Authorization": "Bearer " + token } }).then((resp) => {
                console.log(resp.data);
                setMbSuccess("Employee termination request submitted successfully.");
                setTimeout(() => { window.location.href = "/employeeActions" }, 2000);
            });
        } else {
            console.log("Error in form.  ");
            console.log(errors);
        }
        console.log(termRequest);
    }, [validateAllFields, errors, termRequest, token]);
    
    const onDismissMessage = useCallback(() => {
        setMessageBarShown(false);
    }, [setMessageBarShown]);
    
    if (!hasAccess) {
        return (<h2>You are not authorized to use this application</h2>)
    }

    return (
        <div>
            <div style={{ position: "sticky", top: "20px", left: "10px", zIndex: 1000 }}>
                {messageBarShown &&
                    <MessageBar messageBarType={messageBarType} isMultiline={false} onDismiss={onDismissMessage} dismissButtonAriaLabel="Close">
                        {messageBarText}
                    </MessageBar>
                }
            </div>
            <h2>Terminate Employee</h2>
            <TextField isRequired value={termRequest.employeeName} errorMessage={errors.employeeName} onChange={onNameChange} label="Employee Name" />
            <Dropdown label="Team Leader"
                selectedKey={termRequest.teamLeader ? termRequest.teamLead : undefined}
                options={teamLeaders}
                placeholder="Select the Team Leader"
                onChange={onTeamLeaderChange} />
            <DatePicker label="Termination Date"
                allowTextInput
                value={termDate}
                isRequired
                //onChange={onStartChange} 
                formatDate={onFormatDate}
                onSelectDate={onTerminationDateChange}
                parseDateFromString={onParseDateFromString}
            />

            <TextField value={termRequest.redirectEmailTo} errorMessage={errors.redirectEmailTo} onChange={onRedirectEmailToChange} label="Redirect Email To" />
            <TextField value={termRequest.redirectPhoneTo} errorMessage={errors.redirectPhoneTo} onChange={onRedirectPhoneToChange} label="Redirect Phone To" />
            <br></br>
            <Stack tokens={stackTokens} >
                <Checkbox checked={termRequest.backupEmail} errorMessage={errors.backupEmail} onChange={onBackupEmailChange} label="Backup Email" />
                <Checkbox checked={termRequest.backupOneDrive} errorMessage={errors.backupOneDrive} onChange={onBackupOneDriveChange} label="Backup OneDrive" />
                <Checkbox checked={termRequest.equipmentRecovery} errorMessage={errors.equipmentRecovery} onChange={onEquipmentRecoveryChange} label="Equipment Recovery?" />

            </Stack>

            {termRequest.equipmentRecovery &&
                <div style={{ marginLeft: "25px" }}>
                    <DatePicker label="Recovery Date"
                        allowTextInput
                        value={recoveryDate}
                        isRequired
                        //onChange={onStartChange} 
                        formatDate={onFormatDate}
                        onSelectDate={onRecoveryDateChange}
                        parseDateFromString={onParseDateFromString}
                    />
                    <Checkbox checked={termRequest.retrieveBuildingKey} errorMessage={errors.retrieveBuildingKey} onChange={onRetrieveBuildingKeyChange} label="Retrive Building Key?" />
                    <Dropdown value={termRequest.recoveryMethod} errorMessage={errors.recoveryMethod} onChange={onRecoveryMethodChange} label="Recovery Method" options={dropdownOptions} defaultSelectedKey={"pu"} />
                    <TextField value={termRequest.recoveryDetails} errorMessage={errors.recoveryDetails} onChange={onRecoveryDetailsChange} label="Recovery Details" />
                </div>
            }

            <div>
                <h3>Remove Access to platforms</h3>
                <Stack tokens={stackTokens}>
                    <Checkbox label="CW" checked={termRequest.connectWise} errorMessage={errors.connectWise} onChange={onConnectWiseChange} />
                    <Checkbox label="Know B4" checked={termRequest.knowBe4} errorMessage={errors.knowBe4} onChange={onKnowBe4Change} />
                    <Checkbox label="Yammer" checked={termRequest.yammer} errorMessage={errors.yammer} onChange={onYammerChange} />
                    <Checkbox label="SharePoint" checked={termRequest.sharePoint} errorMessage={errors.sharePoint} onChange={onSharePointChange} />
                    <Checkbox label="IT Glue (If needed)" checked={termRequest.itGlue} errorMessage={errors.itGlue} onChange={onItGlueChange} />
                    <Checkbox label="QuickBooks" checked={termRequest.quickBooks} errorMessage={errors.quickBooks} onChange={onQuickBooksChange} />
                </Stack>
            </div>
            <br />
            <PrimaryButton onClick={onSubmitForm}>Create Ticket</PrimaryButton>

        </div>
    )
}