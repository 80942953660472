import React, { useState, useEffect } from 'react';
import { PrimaryButton, DetailsList } from '@fluentui/react';
import { Client } from '../AbsenceRequestComponents/ApiClient';
import { authProvider } from '../authProvider';
import axios from 'axios';
import { AcceptanceFileList } from './AcceptanceFileList';
const rootUrl = window.location.origin + "/api/";
export const AcceptanceManager = (params) => {
    const [token, setToken] = useState(null)
    const [editedFile, setEditedFile] = useState(null);

    function uploadFile() {
        const url = rootUrl + "documentAcceptance/createDocument";
        var upload = document.getElementById('fileUpload');
        const formData = new FormData();
        formData.append("documentName", "Andy")
        formData.append("uploadedFile", upload.files[0])
        axios.post(url, formData, { headers: { "Content-Type": "multipart/form-data", "Authorization": "bearer " + token } })
        // var client = new Client();
        // client.createDocument()
    }

    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(tkn);
                axios.get("api/DocumentAcceptance/getNettechUsers", {headers: {"Authorization": "Bearer " + tkn}}).then(res=>{
                    console.log(res);
                });
                axios.get("api/DocumentAcceptance/getFileResults/2", {headers: {"Authorization": "Bearer " + tkn}}).then(res=>{
                    console.log(res);
                });
            }
        });
    }, []);

    return (
        <div>
            <h1>Acceptance Manager</h1>
            <h2>Your Files</h2>
            <AcceptanceFileList />
            <h2>Create a new File</h2>
            <input type="file" id="fileUpload"></input>

            <PrimaryButton onClick={uploadFile}>Upload</PrimaryButton>
            <DetailsList items={[]}/>
        </div>
    )
};