import react, { useState, useEffect } from "react";
import { PrimaryButton } from "@fluentui/react";
import { SubmissionResults } from "./SubmissionResults";
import { authProvider } from '../authProvider';

export const EmployeeActions = (params) => {
    
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(()=>{
        authProvider.getIdToken().then((result)=>{
            if (result.idToken.claims.roles.includes("Submit.EmployeeForm")){
                setHasAccess(ha=>true);
            }
        });
    }, [])
    if (!hasAccess){
        return (<h2>You are not authorized to use this application</h2>)
    }
    return (

        <div>
            <h2>Forms</h2>
            <div>
                <PrimaryButton style={{ width: "300px", height: "100px" }} onClick={() => { window.location.href = "/employeeActions/Add" }}>Onboard Employee</PrimaryButton>
                <PrimaryButton style={{ width: "300px", height: "100px", marginTop: "10px", marginLeft: "10px" }} onClick={() => { window.location.href = "/employeeActions/Term" }}>Offboard Employee</PrimaryButton>
            </div>
            <h2>Submission Status</h2>
            <div>
                <SubmissionResults />
            </div>
        </div>
    )
}