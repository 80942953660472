import React, { useState, useCallback, useEffect, useRef } from 'react';
import { CompanyHeaderCell } from './CellComponents/CompanyHeaderCell';
import { DefaultButton, Dropdown, List, Stack, TextField } from '@fluentui/react';
import { HeatMapCell } from './CellComponents/HeatMapCell';
import { HeadingCell } from './CellComponents/HeadingCell';
import { AdoptionCell } from './CellComponents/AdoptionCell';
import { AwarenessCell } from './CellComponents/AwarenessCell';
import { AveragePenetrationCell } from './CellComponents/AveragePenetrationCell';
import { debounce } from 'lodash';
import './HeatMap.css';

export const MasterHeatMap = (props) => {
    const renderFix = props.heatMapState.heatMapData.length;
    const [heatMapData, setHeatMapData] = useState(props.heatMapState.heatMapData || []);
    const [techs, setTechs] = useState(props.heatMapState.techs || []);
    const [options, setOptions] = useState(props.heatMapState.heatMapOptions || []);
    const [techPenetrations, setTechPenetrations] = useState(props.heatMapState.techPenetrations || []);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [displayedCompanies, setDisplayedCompanies] = useState(heatMapData.slice(0, 10));
    const [startIndex, setStartIndex] = useState(0);
    const [canWrite, setCanWrite] = useState(props.canWrite);
    const [territories, setTerritories] = useState([]);
    const [selectedTerritory, setSelectedTerritory] = useState(null);
    const [filterText, setFilterText] = useState("");
    const [pageSize, setPageSize] = useState(11);

    const showCompanies = useCallback((comps) => {
        comps = comps || filteredCompanies
        var startInd = startIndex;
        if (startInd > comps.length - 1) {
            // if the start index is greater than the number of filtered companies,
            // then we need to go back to the last page of filtered companies
            startInd = Math.floor(comps.length / pageSize) * pageSize;
            setStartIndex(startInd);
        }
        setDisplayedCompanies(comps.slice(startInd, startInd + pageSize));
        //setDisplayedCompanies(comps);
    }, [filteredCompanies, setDisplayedCompanies, setStartIndex, startIndex]);
    useEffect(() => {
        setHeatMapData(props.heatMapState.heatMapData || []);
        var sInd = startIndex || 0;
        setTechs(props.heatMapState.techs || []);
        setOptions(props.heatMapState.heatMapOptions || []);
        setTechPenetrations(props.heatMapState.techPenetrations || []);
    }, [props.heatMapState])

    useEffect(() => {
        var allTerritories = heatMapData.map((el) => el.territory);
        var ter = allTerritories.filter((el, index, arr) => {
            return arr.indexOf(el) === index;
        });
        var results = ter.map((el) => { return { key: el, text: el } });
        if (ter.indexOf(null) != -1) {
            results.push({ key: "none", text: "No Territory" });
        }
        if (ter.indexOf(null) == -1) {
            results.push({ key: null, text: "" })
        }
        setTerritories(results);
    }, [heatMapData])
    useEffect(() => {
        showCompanies();
    }, [startIndex])
    const onShowAllClick = useCallback(() => {
        setDisplayedCompanies(heatMapData);
    }, [setDisplayedCompanies, heatMapData]);

    useEffect(() => {
        setCanWrite(props.canWrite)
    }, [props.canWrite]);

    const onNextClick = useCallback(() => {
        var newStartIndex = startIndex + pageSize;
        if (newStartIndex > heatMapData.length - 1) {
            return;
        }
        setStartIndex(newStartIndex);

    }, [startIndex, setStartIndex, setDisplayedCompanies, heatMapData]);
    const onPrevClick = useCallback(() => {
        var newStartIndex = startIndex - pageSize;
        if (newStartIndex < 0) {
            newStartIndex = 0;
        }
        setStartIndex(newStartIndex);

    }, [startIndex, setStartIndex, setDisplayedCompanies, heatMapData]);

    const filterCompanies = (hmData, setCompanies, compFilter, selTer) => {
        var companies = hmData.filter((el) => {
            var res = true;

            if (selTer && (el.territory != selTer && (selTer != "none" || (selTer == "none" && el.territory != null)))) {
                res = false;
            }
            if (compFilter && el.customerName.toLowerCase().indexOf(compFilter.toLowerCase()) == -1) {
                res = false;
            }
            return res;
        });
        setCompanies(companies);
    };

    const debouncedFilterCompanies = useRef(debounce(filterCompanies, 500)).current;

    const onTerritorySelected = useCallback((evt, option) => {
        setSelectedTerritory(option.key);
    }, [setSelectedTerritory, filterCompanies]);

    const onFilterTextChange = useCallback((evt, value) => {
        setFilterText(value);
    }, [setFilterText, filterCompanies]);

    useEffect(() => {
        showCompanies();
    }, [filteredCompanies])

    useEffect(() => {
        debouncedFilterCompanies(heatMapData, setFilteredCompanies, filterText, selectedTerritory);
    }, [filterText, selectedTerritory, heatMapData])
    function renderPages() {

        var numPages = Math.ceil(filteredCompanies.length / pageSize);
        var items = [];
        for (var i = 0; i < numPages; i++) {
            items.push({ key: i, text: i + 1 });
        }
        return (<Dropdown style={{ width: "55px" }} options={items} selectedKey={startIndex / pageSize} onChange={(evt, option) => { setStartIndex(option.key * pageSize); }} />);
    }

    return (<div key={renderFix}>

        <Stack horizontal > 
            <TextField style={{ minWidth: "150px" }} label="Company Filter" onChange={onFilterTextChange} value={filterText} />
            <Dropdown style={{ width: "220px", minWisth: "220px" }} label="Territory" options={territories} onChange={onTerritorySelected} />
        </Stack>
        <Stack horizontal>
            <DefaultButton text="Previous" onClick={onPrevClick} />
            {renderPages()}
            <DefaultButton text="Next" onClick={onNextClick} />
            <div style={{ padding: 5 }}> <span style={{ fontWeight: "bold" }}>Filtered Companies:</span> {filteredCompanies.length}</div>
            <div style={{ padding: 5 }}> <span style={{ fontWeight: "bold" }}>Total Companies:</span> {heatMapData.length}</div>
        </Stack>
        {heatMapData.length == 0 && <div>
            No data was loaded.
        </div>}
        {heatMapData.length > 0 && <div className="heatmapContainer">
            
                <div className='heatmapColumn'>
                    {/*Tech Penetration Header Row */}
                    <Stack horizontal>
                        <HeadingCell text="Average Tech Penetration:"  width="374px" backgroundColor={"#FFFFFF"} height={"18.4px"} />
                    </Stack>
                    {/* Tech Header row */}
                    <Stack horizontal>
                        <HeadingCell text="Account" height={"37px"} />
                        <HeadingCell text="Adoption" width={"77px"} />
                        <HeadingCell text="Awareness" width={"77px"} />
                    </Stack>
                    {/* Company Rows */}
                    {displayedCompanies.map((customer, index) => {
                        const rowKey = `hmRow${customer.companyId}`;
                        //console.log(`rowKey: ${rowKey}`)
                        return (
                            <Stack key={rowKey} horizontal >
                                <CompanyHeaderCell customer={customer} />
                                <AdoptionCell value={customer.adoption} />
                                <AwarenessCell value={customer.awareness} />
                            </Stack>);
                    })}
                </div>
                <div className="heatmapColumn heatmapItemColumn">
                    {/* Display Average Tech Penetration */}
                    <Stack horizontal>

                        {techPenetrations.map((techPenetration, index) => {
                            return (<AveragePenetrationCell key={`techPenetration${index}`} value={techPenetration} />)
                        })}


                    </Stack>
                    {/* Display the headers */}
                    <Stack horizontal>

                        {techs.map((techItem, index) => {
                            return (<HeadingCell key={`techHeadingCell${index}`} text={techItem.name} backgroundColor={techItem.color} width={"176.6px"} />);
                        })}
                    </Stack>

                    {/* Display the Heat Map */}
                    {displayedCompanies.map((customer, index) => {
                        const rowKey = `hmRow${customer.companyId}`;
                        //console.log(`rowKey: ${rowKey}`)
                        return (
                            <Stack key={rowKey} horizontal >
                                {
                                    techs.map((techItem, ind) => {
                                        var heatMapItemKey = `hsc${index}_${ind}`
                                        //console.log(`heatMapItemKey: ${heatMapItemKey}`)
                                        var custTechItem = customer.techs.find((el) => el.techId == techItem.id);
                                        if (!custTechItem) {
                                            return (<HeatMapCell key={heatMapItemKey} canWrite={canWrite} options={options} techId={techItem.id} companyId={customer.companyId} onOptionChange={props.onOptionChange} />)
                                        }
                                        var result = (<HeatMapCell key={heatMapItemKey} canWrite={canWrite} options={options} selectedOptionId={custTechItem.heatMapOptionId} techId={techItem.id} companyId={customer.companyId} onOptionChange={props.onOptionChange} />);
                                        return result;
                                    })
                                }
                            </Stack>);
                    })}
                </div>            
        </div>}
    </div>)
}