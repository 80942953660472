import { Client, PendingRequest } from "./ApiClient";
import * as React from "react";
import * as ReactDOM from "react-dom"
import { TextField } from '@fluentui/react/lib/TextField';
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Modal } from '@fluentui/react/lib/Modal';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { AbsenceRequest } from "./AbsenceRequest"
const moment = require('moment');


interface IYourRequestState {
    requests: PendingRequest[],
    modalVisible: boolean,
    viewedItem: PendingRequest,
    rejectDialogVisible: boolean,
    denialReason: string,
    showApproved: boolean,
    isSaving: boolean, 
}
interface IPendingRequestProps {
    viewHandled?(): void,
    viewId?: number,
}
const _columns = [
    { key: 'column1', name: "Employee", fieldName: "employeeName", minWidth: 100, maxWidth: 100, isResizeable: true },
    { key: 'status', name: "Status", fieldName: "status", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'from', name: "From", fieldName: "absentFrom", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'to', name: "To", fieldName: "absentTo", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'hours', name: "Hours", fieldName: "numberOfHours", minWidth: 50, maxWidth: 50, isResizeable: true },
    { key: 'absencetype', name: 'Absence Type', fieldName: 'typeOfAbsence', minWidth: 150, maxWidth: 150, isResizeable: true },
    { key: 'reason', name: "Reason", fieldName: "reasonForAbsence", minWidth: 100, maxWidth: 300, isResizeable: true, isMultiline: true },
    { key: 'view', name: "", fieldName: "viewButton", minWidth: 20, maxWidth: 20, isResizeable: false },
    { key: 'approve', name: "", fieldName: "approveButton", minWidth: 20, maxWidth: 20, isResizeable: false },
    { key: 'reject', name: "", fieldName: "rejectButton", minWidth: 20, maxWidth: 20, isResizeable: false }
];

export class PendingRequests extends React.Component<IPendingRequestProps, IYourRequestState> {
    constructor(props: {}) {
        super(props);
        //var req = new PendingRequestModel();
        //req.employeeName = "Andy";
        //req.status = "Pending";
        //req.absentFrom = new Date();
        //req.absentTo = new Date();
        //req.numberOfHours = "40";
        //req.typeOfAbsence = "Vacation";
        //req.reasonForAbsence = "Fun";

        this.state =
            {
                requests: new Array<PendingRequest>(),
                modalVisible: false,
                viewedItem: new PendingRequest(),
                rejectDialogVisible: false,
                denialReason: "",
            showApproved: false,
                isSaving: false
            };

  
        var client = new Client();
        
        client.getPendingRequests(this.state.showApproved).then((data) => {
            this.setState({ requests: data });
            if (this.props.viewId) {
                if (this.props.viewHandled) {
                    this.props.viewHandled();
                }

                var res = data.find(req => req.id === this.props.viewId);
                if (res) {
                    this._showView(res);
                }

            }
        });
    }
    render() {
        const { requests } = this.state;
        return (
            <div>
                <h1>Pending Requests</h1>
                <p><Checkbox label="Show all requests?" checked={this.state.showApproved} onChange={this._onShowApprovedChanged} /></p>
                <Modal isOpen={this.state.isSaving} isBlocking={true}>
                    <div style={{ padding: "10px" }}>
                        <Spinner label="Please Wait..." />
                    </div>
                </Modal>

                <Modal isOpen={this.state.modalVisible}
                    onDismiss={this._closeView}
                    isBlocking={false}
                >
                    <div>
                        <AbsenceRequest absenceRequest={this.state.viewedItem} />
                        <div>
                            <IconButton disabled={this.state.viewedItem.status && this.state.viewedItem.status != "Pending"} iconProps={{ iconName: "Accept" }} onClick={(): void => {
                                this._onApprove(this.state.viewedItem);
                                this.setState({ modalVisible: false });
                            }} />
                            <IconButton disabled={this.state.viewedItem.status && this.state.viewedItem.status != "Pending"} iconProps={{ iconName: "Cancel" }} onClick={(): void => {
                                this.showRejectionDialog(this.state.viewedItem);
                            }} />
                        </div>
                    </div>
                </Modal>
                <Dialog
                    hidden={!this.state.rejectDialogVisible}
                    onDismiss={this._closeRejectionDialog}
                    dialogContentProps={{ type: DialogType.largeHeader, title: "Reject " + this.state.viewedItem.employeeName + "'s Absence Request" }}
                    modalProps={{
                        isBlocking: false,
                    }}
                >
                    <div>
                        <TextField value={this.state.denialReason} rows={4} label="Reason for Rejection"  onChange={this._denialReasonChange} />
                    </div>
                    <DialogFooter>
                        <PrimaryButton onClick={(): void => { this.setState({ rejectDialogVisible: false }); this._onReject(this.state.viewedItem, this.state.viewedItem.denialReason); }} text="Reject" />
                        <DefaultButton onClick={this._closeRejectionDialog} text="Cancel" />
                    </DialogFooter>
                </Dialog>
                <DetailsList items={requests}
                    columns={_columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    compact={true}
                    onRenderItemColumn={this._renderItemColumn}
                    selectionMode={SelectionMode.none}
                    onItemInvoked={this._onItemInvoked}
                    constrainMode={ConstrainMode.unconstrained}
                />
            </div>
        );
    }
    private _renderItemColumn = (item: any, index: number, column: IColumn): any => {
        const fieldContent = item[column.fieldName || ''];

        var abId = "ab" + index;
        var rbId = "rb" + index;
        var vbId = "vb" + index;
        switch (column.key) {
            case 'to':
            case 'from':
                return <span>{moment(fieldContent).format("l")}</span>;
            case 'approve':
                return <TooltipHost content="Approve request" id={abId}>
                    <IconButton disabled={item.status && item.status != "Pending"} text="Approve" iconProps={{ iconName: "Accept" }} onClick={(): void => { this._onApprove(item); }} aria-describedby={abId} /></TooltipHost>;
            case 'reject':
                return <TooltipHost content="Reject request" id={rbId}>
                    <IconButton disabled={item.status && item.status != "Pending"} text="Reject" iconProps={{ iconName: 'Cancel' }} onClick={(): void => {
                        this.showRejectionDialog(item);
                    }} aria-describedby={rbId} />
                </TooltipHost>;
            case 'view':
                return <TooltipHost content="View request" id={vbId}>
                    <IconButton text="View" iconProps={{ iconName: 'RedEye' }} onClick={(): void => {
                        this._onView(item);
                    }} aria-describedby={vbId} /></TooltipHost>;
            default:
                return <span>{fieldContent}</span>;
        }

    }
    private _onItemInvoked = (item?: any, index?: number, ev?: Event): void | boolean => {
        if (item) {
            alert("Invoked " + item);
        }
    }
    private _onApprove = (item: PendingRequest): void => {
        var client = new Client();
        this.setState({ isSaving: true });
        client.approveRequest(item.id).then((value: boolean): void => {
            var reqs = new Array<PendingRequest>();
            reqs = reqs.concat(this.state.requests);
            var fitem = reqs.find(x => x.id == item.id);
            fitem.status = "Approved";

            this.setState({ requests: reqs, modalVisible: false, isSaving: false });
        });
    };
    private _onReject = (item: PendingRequest, reason: string): void => {
        var client = new Client();
   
        client.rejectRequest(item.id, this.state.denialReason).then((value: boolean): void => {
            var reqs = new Array<PendingRequest>();
            reqs = reqs.concat(this.state.requests);
            var fitem = reqs.find(x=>x.id == item.id);
            fitem.status = "Denied";
            fitem.denialReason = this.state.denialReason;
            this.setState({ requests: reqs, modalVisible: false  });
          
        });
    }
    private _onView = (item: PendingRequest): void => {
        this._showView(item);
    };
    private _showView = (item: PendingRequest): void => {
        this.setState({
            viewedItem: item,
            modalVisible: true
        });
    }
    private showRejectionDialog = (item: PendingRequest): void => {
        this.setState({ viewedItem: item, rejectDialogVisible: true, denialReason: "" });
    };

    private _denialReasonChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {

        this.setState({ denialReason: newValue });
    };
    private _closeView = (): void => {
        this.setState({ modalVisible: false });
    }
    private _closeRejectionDialog = (): void => { this.setState({ rejectDialogVisible: false }); }
    private _reloadRequests = (showApproved: boolean): void => {

        var client = new Client();
        client.getPendingRequests(showApproved).then((data) => {
            this.setState({ requests: data });
           
        });
    }
    private _onShowApprovedChanged = (ev: React.FormEvent<HTMLElement>, isChecked: boolean): void => {
        this.setState({ showApproved: isChecked });
        this._reloadRequests(isChecked);
    }
   
}