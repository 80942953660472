import React, { useState, useCallback, useEffect } from "react";
import { TextField, Checkbox, MaskedTextField, Stack, PrimaryButton, DatePicker, Dropdown, MessageBar, MessageBarType } from "@fluentui/react";
import { authProvider } from '../authProvider';
import axios from "axios";


const rootUrl = window.location.origin + "/api/employeeforms/";
const stackTokens = { childrenGap: 10 };
const baseAddRequest = {
    legalName: "",
    startDate: "",
    team: "",
    position: "",
    teamLeader: "",
    location: "",
    setupProfile: "",
    monitors: 0,
    laptop: false,
    dock: false,
    keyboard: false,
    mouse: false,
    printer: false,
    headset: "",
    accessToOffice: false,
    connectWise: false,
    knowBe4: false,
    yammer: false,
    sharePoint: false,
    itGlue: false,
    quickBooks: false,
    notes: ""
}
const baseErrors = {
    legalName: "",
    startDate: "",
    team: "",
    position: "",
    teamLeader: "",
    location: "",
    setupProfile: "",
    monitors: "",
    laptop: "",
    dock: "",
    keyboard: "",
    mouse: "",
    printer: "",
    headset: "",
    accessToOffice: "",
    connectWise: "",
    knowBe4: "",
    yammer: "",
    sharePoint: "",
    itGlue: "",
    quickBooks: "",
    notes: "",
}

// Format Date as MM/DD/YYYY
const onFormatDate = (date) => {
    return !date ? '' : (date.getMonth() + 1) + '/' + (date.getDate()) + '/' + (date.getFullYear());
};

export const AddEmployeeForm = (params) => {

    const [addRequest, setAddRequest] = useState({ ...baseAddRequest });
    const [errors, setErrors] = useState({ ...baseErrors })
    const [startDate, setStartDate] = useState();
    const [token, setToken] = useState();
    const [hasAccess, setHasAccess] = useState(false);
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [messageBarShown, setMessageBarShown] = useState(false);
    const [messageBarText, setMessageBarText] = useState("");
    const [messageBarType, setMessageBarType] = useState(MessageBarType.success)
    const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
    // Date Management
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(token => tkn);
                axios.get(rootUrl + "TeamLeaders", { headers: { "Authorization": "Bearer " + token } }).then((result) => {
                    setTeamLeaders(result.data.map((tl) => { return { key: tl.id, text: tl.name } }));
                });
            }
        });
        authProvider.getIdToken().then((result) => {
            if (result.idToken.claims.roles.includes("Submit.EmployeeForm")) {
                setHasAccess(ha => true);
            }
        });
    }, []);



    const onParseDateFromString = useCallback(
        (newValue) => {
            const value = addRequest.startDate
            const previousValue = value || new Date();
            const newValueParts = (newValue || '').trim().split('/');
            const day =
                newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[1], 10))) : previousValue.getDate();
            const month =
                newValueParts.length > 1
                    ? Math.max(1, Math.min(12, parseInt(newValueParts[0], 10))) - 1
                    : previousValue.getMonth();
            let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
            if (year < 100) {
                year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
            }
            return new Date(year, month, day);
        },
        [addRequest]
    );

    // Validation
    const setError = useCallback((fName, message) => {
        setErrors((e) => {
            var nState = { ...e };
            nState[fName] = message;
            return nState;
        });

    }, [setErrors]);

    const validateNotEmpty = useCallback((fieldName, message) => {
        var value = addRequest[fieldName];
        var result = value && value.length > 0;
        if (!result) {
            if (!message) {
                message = fieldName + " must have a value."
            }
            setError(fieldName, message)
        }
        return result
    }, [addRequest, setError]);

    const validateLegalName = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("legalName", "Legal Name is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("legalName", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateTeam = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("team", "Team is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("team", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validatePosition = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("position", "Position is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("position", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateTeamLeader = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("teamLeader", "Team Leader is required!")) {
            result = false;
        }

        // if there are no errors, clear the error message.
        if (result) {
            setError("teamLeader", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateLocation = useCallback(() => {
        var result = true;
        if (!validateNotEmpty("location", "Location is required!")) {
            result = false;
        }
        //if there are no errors, clear the message.
        if (result) {
            setError("location", "");
        }

        return result;
    }, [setError, validateNotEmpty]);

    const validateAllFields = useCallback(() => {
        var passed = true;
        if (!validateLegalName()) {
            passed = false;
        }
        if (!validateTeam()) {
            passed = false;
        }
        if (!validatePosition()) {
            passed = false;
        }
        if (!validateTeamLeader()) {
            passed = false;
        }
        if (!validateLocation()) {
            passed = false;
        }
        return passed;
    }, [validateLegalName, validateTeam, validatePosition, validateTeamLeader])

    // Handle Text field updates    
    const setField = useCallback((fieldName, evt) => {
        setAddRequest((req) => {
            const nReq = { ...req }
            nReq[fieldName] = evt.target.value;
            return nReq;
        });


    }, [setAddRequest]);

    const onNameChange = useCallback((evt) => {
        setField("legalName", evt);
    }, [setField])
    const onNotesChange = useCallback((evt) => {
        setField("notes", evt)
    }, [setField]); 
    const onStartChange = useCallback((date) => {
        setAddRequest((req) => {
            const nReq = { ...req };
            nReq.startDate = onFormatDate(date);
            return nReq;
        })
        setStartDate(date);
    }, [setAddRequest, setStartDate]);

    const onTeamChange = useCallback((evt) => {
        setField("team", evt);
    }, [setField]);

    const onPositionChange = useCallback((evt) => {
        setField("position", evt);
    }, [setField]);



    const onLocationChange = useCallback((evt) => {
        setField("location", evt);
    }, [setField]);

    const onSetupProfileChange = useCallback((evt) => {
        setField("setupProfile", evt);
    }, [setField]);

    const onMonitorsChange = useCallback((evt, val) => {
        // Overridden to use the value passed by the masked text box.
        setAddRequest((req) => {
            const nReq = { ...req };
            nReq.monitors = val;
            return nReq;
        })
        //setField("monitors", evt);
    }, [setAddRequest]);

    const onHeadsetChange = useCallback((evt) => {
        setField("headset", evt);
    }, [setField]);

    // Handle Checkbox updates
    const setCheckbox = useCallback((fieldName, checked) => {
        setAddRequest((req) => {
            const nReq = { ...req };
            nReq[fieldName] = checked;
            console.log("Set " + fieldName + " to " + checked);
            return nReq;
        });

    }, [addRequest, setAddRequest]);

    const onLaptopChange = useCallback((ev, checked) => {
        setCheckbox("laptop", checked);
    }, [setCheckbox]);

    const onDockChange = useCallback((ev, checked) => {
        setCheckbox("dock", checked);
    }, [setCheckbox]);

    const onKeyboardChange = useCallback((ev, checked) => {
        setCheckbox("keyboard", checked);
    }, [setCheckbox]);

    const onMouseChange = useCallback((ev, checked) => {
        setCheckbox("mouse", checked);
    }, [setCheckbox]);

    const onPrinterChange = useCallback((ev, checked) => {
        setCheckbox("printer", checked);
    }, [setCheckbox]);

    const onAccessToOfficeChange = useCallback((ev, checked) => {
        setCheckbox("accessToOffice", checked);
    }, [setCheckbox]);

    const onConnectWiseChange = useCallback((ev, checked) => {
        setCheckbox("connectWise", checked);
    });

    const onKnowBe4Change = useCallback((ev, checked) => {
        setCheckbox("knowBe4", checked);
    });

    const onYammerChange = useCallback((ev, checked) => {
        setCheckbox("yammer", checked);
    });

    const onSharePointChange = useCallback((ev, checked) => {
        setCheckbox("sharePoint", checked);
    });

    const onItGlueChange = useCallback((ev, checked) => {
        setCheckbox("itGlue", checked);
    });

    const onQuickBooksChange = useCallback((ev, checked) => {
        setCheckbox("quickBooks", checked);
    })

    const setMbError = useCallback((message) => {
        setMessageBarText(message);
        setMessageBarShown(true);
        setMessageBarType(MessageBarType.error);
    }, [setMessageBarText, setMessageBarShown, setMessageBarType]);

    const setMbSuccess = useCallback((message) => {
        setMessageBarText(message);
        setMessageBarShown(true);
        setMessageBarType(MessageBarType.success);
    }, [setMessageBarText, setMessageBarShown, setMessageBarType]);
    // Handle Dropdown updates
    const setDropdown = useCallback((fieldName, value) => {
        setAddRequest((req) => {
            const nReq = { ...req };
            nReq[fieldName] = value;
            return nReq;

        });
    }, [setAddRequest]);

    const onTeamLeaderChange = useCallback((evt, option) => {
        setDropdown("teamLeader", option.key);
    }, [setDropdown]);

    const onDismissMessage = useCallback(() => {
        setMessageBarShown(false);
    }, [setMessageBarShown]);


    function takeFocus(el){
        el.focus();
    }
    // Submission   
    const onSubmitForm = useCallback(() => {
        if (validateAllFields()) {
            console.log("Submitting Form");
            axios.post(rootUrl + "AddEmployee", addRequest, { headers: { "Authorization": "Bearer " + token } }).then((resp) => {
                console.log(resp.data);
                setMbSuccess("Employee setup request submitted successfully.");
                setTimeout(() => { window.location.href = "/employeeActions" }, 2000);
            }).catch((err) => {
                setMbError("Unknown error submitting employee setup request.");
            });
        } else {
            console.log("Error in form.  ")
            console.log()
        }
        console.log(errors);
        console.log(addRequest);
    }, [validateAllFields, errors, addRequest, token, setMbError, setMbSuccess])

    if (!hasAccess) {
        return (<h2>You are not authorized to use this application</h2>)
    }
    return (

        <div>
            <div style={{ position: "sticky", top: "20px", left: "10px", zIndex: 1000 }}>
                {messageBarShown &&
                    <MessageBar messageBarType={messageBarType} isMultiline={false} onDismiss={onDismissMessage} dismissButtonAriaLabel="Close">
                        {messageBarText}
                    </MessageBar>
                }
            </div>
            <h2>Add Employee Form</h2>
            <TextField label="Full Legal Name" value={addRequest.legalName} errorMessage={errors.legalName} onChange={onNameChange} />
            <DatePicker label="Start Date"
                allowTextInput
                value={startDate}
                isRequired
                //onChange={onStartChange} 
                formatDate={onFormatDate}
                onSelectDate={onStartChange}
                parseDateFromString={onParseDateFromString}
            />

            <TextField label="Team" value={addRequest.team} errorMessage={errors.team} onChange={onTeamChange} />
            <TextField label="Position" value={addRequest.position} errorMessage={errors.position} onChange={onPositionChange} />
            <Dropdown label="Team Leader"
                selectedKey={addRequest.teamLeader ? addRequest.teamLeader : undefined}
                options={teamLeaders}
                placeholder="Select the Team Leader"
                onChange={onTeamLeaderChange} />

            <TextField label="Location (city/state)" value={addRequest.location} errorMessage={errors.location} onChange={onLocationChange} />

            <TextField label="Setup: *Team or similar profile already est   ablished by another employee" value={addRequest.setupProfile} onChange={onSetupProfileChange} />

            <h3>Equipment needed: </h3>
            <Stack tokens={stackTokens}>
                <MaskedTextField label="Monitors (1 or 2)" mask="9"  value={addRequest.monitors} errorMessage={errors.monitors} onChange={onMonitorsChange} />
                <TextField label="Headset (enter new hire's choice)" value={addRequest.headset} errorMessage={errors.headset} onChange={onHeadsetChange} />
                <Checkbox label="Laptop" checked={addRequest.laptop} errorMessage={errors.laptop} onChange={onLaptopChange} />
                <Checkbox label="Dock" checked={addRequest.dock} errorMessage={errors.dock} onChange={onDockChange} />
                <Checkbox label="Keyboard" checked={addRequest.keyboard} errorMessage={errors.keyboard} onChange={onKeyboardChange} />
                <Checkbox label="Mouse" checked={addRequest.mouse} errorMessage={errors.mouse} onChange={onMouseChange} />
                <Checkbox label="Printer (if needed)" checked={addRequest.printer} errorMessage={errors.printer} onChange={onPrinterChange} />
                <Checkbox label="Access to Office (if needed)" checked={addRequest.accessToOffice} errorMessage={errors.accessToOffice} onChange={onAccessToOfficeChange} />
            </Stack>

            <h3>Access to platforms w/training assigned</h3>
            <Stack tokens={stackTokens}>
                <Checkbox label="CW" checked={addRequest.connectWise} errorMessage={errors.connectWise} onChange={onConnectWiseChange} />
                <Checkbox label="Know B4" checked={addRequest.knowBe4} errorMessage={errors.knowBe4} onChange={onKnowBe4Change} />
                <Checkbox label="Yammer" checked={addRequest.yammer} errorMessage={errors.yammer} onChange={onYammerChange} />
                <Checkbox label="SharePoint" checked={addRequest.sharePoint} errorMessage={errors.sharePoint} onChange={onSharePointChange} />
                <Checkbox label="IT Glue (If needed)" checked={addRequest.itGlue} errorMessage={errors.itGlue} onChange={onItGlueChange} />
                <Checkbox label="QuickBooks" checked={addRequest.quickBooks} errorMessage={errors.quickBooks} onChange={onQuickBooksChange} />
            </Stack>
            <TextField label="Notes" value={addRequest.notes} onChange={onNotesChange} multiline rows={5}/>
            <br />
            {(!submittedSuccessfully) &&
                <PrimaryButton onClick={onSubmitForm}>Create Ticket</PrimaryButton>
            }
        </div>
    )
}