import React, { useState, useCallback, useEffect } from "react";
import { authProvider } from '../authProvider';
import axios from "axios";
import { DetailsList, SelectionMode, DetailsRow, IconButton } from "@fluentui/react";

const rootUrl = window.location.origin + "/api/EstimateActionPlan/";

const columnDefs = [
    { key: "estimateId", name: "Id", fieldName: "id", minWidth: 50, maxWidth: 50, isResizable: true },
    { key: "customerName", name: "Company", "fieldName": "company", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "quoteNumber", name: "Quote Number", "fieldName": "quoteNumber", minWidth: 100, maxWidth: 100, isResizable: true },
    { key: "projectName", name: "Title", "fieldName": "projectName", minWidth: 100,  isResizable: true },
    { key: "documentLink", name: "", "fieldName": "documentUrl", minWidth: 50, maxWidth: 50, isResizable: false, 
        onRender: (item) => {
            return (<div>
                {item.documentUrl &&
                    <IconButton iconProps={{ iconName: "WordDocument" }} onClick={() => window.open(item.documentUrl, "_blank")} />
                }
            </div>)
        }
    }
]
export const EstimateSelection = (props) => {
    var [estimates, setEstimates] = useState([]);
    var [token, setToken] = useState("");
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(token => tkn);
                axios.get(rootUrl + "getEstimateList", { headers: { "Authorization": "Bearer " + tkn } }).then((result) => {
                    setEstimates(result.data);
                });
            }
        });

    }, []);

    const onRenderRow = (props, defaultRender) => {

        return (
            <DetailsRow {...props} onDoubleClick={() => window.location.href = "/ProjectActionPlan/" + props.item.id} />
        );
    };
    return (
        <div>
            <h2>Project Action Plan</h2>
            <h3>Estimates and Action Plans</h3>
            <p>Double click an estimate to manage its tasks.  All estimates shown have been approved by sales and service.</p>
            <DetailsList onRenderRow={onRenderRow} style={{ maxHeight: "500px", overflowY: "auto" }} items={estimates} columns={columnDefs} selectionMode={SelectionMode.none} />
        </div>
    );
}