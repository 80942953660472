import React, { useState, useRef, useEffect } from 'react';

export const EditableCell = ({ item, index, column, updateItem }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(item[column.fieldName]);
    const inputRef = useRef(null);

    useEffect(()=>{
        if (isEditing){
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);
    
    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setEditValue(e.target.value);
    };

    const handleBlur = () => {
        if (item[column.fieldName] != editValue) {
            updateItem(item.ticketId, column.fieldName, editValue);
        }
        setIsEditing(false);
    };

    return isEditing ? (
        <input ref={inputRef} type="text" value={editValue} style={{width:"100%", boxSizing: "border-box"}} onChange={handleChange} onBlur={handleBlur} autoFocus />
    ) : (
        <div onClick={handleEdit}>{item[column.fieldName]}</div>
    );
}