import { List, PrimaryButton } from "@fluentui/react"
import { useHistory } from "react-router-dom"
import * as React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { authProvider } from './authProvider'
//var apps = [
//    ,
//    { name: "Sentinel One Mappings", description: "Map companies from ConnectWise to Sentinel One Sites", url: "/sentinelmapping" },
//    { name: "Purchase Requests", description: "Submit a purchase request", url: "/purchaserequest" }
//];


function Home(props) {
    const [apps, setApps] = useState([
        { name: "Absence Request", description: "Use this application to request time off.", url: "/absencerequest" },
        { name: "HPE Bill of Materials Product Line Total", description: "Upload a bill of materials for totals by product line.", url: "/hpebom" },
        { name: "Client Master Heatmap", description: "Provides information on the status of each company in regards to different technologies", url: "/ClientMasterHeatMap"},
        { name: "Internal Development Ticket Priority", description: "Allow you to view and communicate the priority of your tickets to development", url:"/DevPriority"}
    ])
    const history = useHistory();

    useEffect(() => {
        authProvider.getIdToken().then((res) => {
            var lApps = [...apps];
            var changed = false;
            if (res.idToken.claims.roles.includes("SentinelMapper")) {
                changed = true;
                lApps.push({ name: "Sentinel One Mappings", description: "Map companies from ConnectWise to Sentinel One Sites", url: "/sentinelmapping" });
            }
            if (res.idToken.claims.roles.includes("AuvikMapper")) {
                changed = true;
                lApps.push({ name: "Auvik to CW Mappings", description: "Map companies from ConnectWise to Auvik ", url: "/auvikMapping" });
            }
            if (res.idToken.claims.roles.includes("AuvikMapper")) {
                changed = true;
                lApps.push({ name: "Datto to CW Mappings", description: "Map companies from ConnectWise to Auvik ", url: "/dattoMapping" });
            }
            if (res.idToken.claims.roles.includes("Submit.EmployeeForm")) {
                changed = true;
                lApps.push({ name: "Add or Terminate Employee", description: "Forms allowing you to create a ticket to add or terminate an employee", url: "/employeeActions" });
            }
            if (res.idToken.claims.roles.includes("CompanyTeamCleanup")){
                changed = true;
                lApps.push({ name: "Company Teams Cleanup", description: "Form allowing you to remove all company team membership for a user", url: "/companyTeamCleanup" });
            }
            if (res.idToken.claims.roles.includes("HPSalesTrackerImporter")) {
                changed = true;
                lApps.push({ name: "HP Sales Tracker Importer", description: "Upload HPE Sales Tracker Excel Files for Reporting Annual Sales", url: "/HpSalesImporter" });
            }
            if (res.idToken.claims.roles.includes("RedLightGreenLight.User"))
            {
                changed = true;
                lApps.push({ name: "Red Light Green Light", description: "View and update the status of your tickets", url: "/RedLightGreenLight" });
            }


            if (changed) {
                setApps(lApps);
            }
        });

    }, []);
    const onRenderCell = (item: any, index: number | undefined): JSX.Element => {
        return (
            <div>
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <PrimaryButton onClick={() => { history.push(item.url); }} text="Open" />
            </div>
        );
    };

    return <div className="container">
        <h2>Welcome</h2>
        <p>Choose the application wish to use from the list below:</p>
        <List items={apps} onRenderCell={onRenderCell}>
        </List>
    </div>;
}
export default Home;