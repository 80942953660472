import * as React from "react";
import { Dropdown, IDropdownStyles, loadTheme, PrimaryButton, ResponsiveMode } from '@fluentui/react';
import { NettechTheme } from "../themes/NettechTheme";
import { authConfig } from '../AbsenceRequestComponents/ApiClientConfig';
import { authProvider } from '../authProvider';
import { Client } from "../AbsenceRequestComponents/ApiClient";
import { Fabric } from '@fluentui/react/lib/Fabric';
import { List } from '@fluentui/react/lib/List';
interface IMapperProps {

}
interface IMapperState {

}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 363 },
};
export class DattoMappingList extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        var self = this;
        this.state = { mappings: [], cwCompanies: [] };
        loadTheme(NettechTheme);
        this.render = this.render.bind(this);
        this.onRenderListCell = this.onRenderListCell.bind(this);
        this.onDropDownChange = this.onDropDownChange.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onCwRefresh = this.onCwRefresh.bind(this);
        authProvider.getAccessToken().then(function (result) {
            var tkn = result.accessToken;

            if (tkn) {
                self.setState({ token: tkn, authenticated: true });
                authConfig.token = tkn;
                var client = new Client();
                client.dattoAll().then((res) => {
                    self.setState({ mappings: res });
                });
                client.cwCompanies().then((res) => {
                    var comps = res.map((itm, ind) => {
                        return { key: itm.id, text: itm.name }
                    })
                    self.setState({ cwCompanies: comps });
                });
                //client.getUserInfo().then((res) => {
                //    self.setState({
                //        pendingVisible: res.isManager,
                //        approvedVisible: res.isHr,
                //        authenticated: true,
                //        name: res.name
                //    });

                //});


            }
        });
    }
    
    onRenderListCell(item?: any, index?: number) {
        const companies = this.state?.cwCompanies ? this.state.cwCompanies : [];
        return (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm4">
                       {item.dattoSiteName}
                    </div>
                    <div className="ms-Grid-col ms-sm8">
                        <Dropdown options={this.state.cwCompanies} selectedKey={item.cwCompanyId} onChange={(evt, itm) => { this.onDropDownChange(evt, itm, item) }} styles={dropdownStyles} calloutProps={{calloutMaxHeight: 300}} />
                    </div>
                </div>
            </div>
        );
    }
    onDropDownChange(evt, itm, mapping) {
        mapping.cwCompanyId = itm.key;
        mapping.cwCompanyName = itm.text;
        var client = new Client();
        var mappings = [...this.state.mappings];
        client.datto(mapping).then((res) => {
            var idx = mappings.findIndex(m => m.dattoSiteUid == res.dattoSiteUid);
            mappings.splice(idx, 1, res);
            this.setState({ mappings: mappings });
        });
    }
    onRefresh() {
        var client = new Client();
        client.refreshDatto().then((res) => {
            this.setState({ mappings: res });
        });
    }
    onCwRefresh() {
        var client = new Client();
        client.refreshCwCompanies().then((res) => {
            this.setState({ cwCompanies: res });
        });
    }
    render() {

        const mappings = this.state.mappings ? this.state.mappings : [];
        return (<Fabric>
            <h2>Datto to Connectwise Mapping</h2>
            <p>Each site defined in Datto is listed below.  Beside each tenant is a dropdown containing the
                ConnectWise companies the tenant can be linked to.  Simply select a company from the list, and the mappings
                will be updated to match.  There is no save button.  The data is updated when you select the item in the dropdown.</p>
            <List items={mappings} onRenderCell={this.onRenderListCell} />
            <br />
            <PrimaryButton text="Refresh Datto Companies" onClick={this.onRefresh} />
            <PrimaryButton text="Refresh ConnectWise Companies" onClick={this.onCwRefresh} />
        </Fabric>);
    }
}