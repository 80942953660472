import { Text, TooltipHost } from '@fluentui/react';
import React from 'react';

const headerStyles = {
    backgroundColor: "#ffffff",
    fontWeight: "bold",
    width: "200px",
    height: "32px",
    overflow: "wrap",
    minWidth: "200px",
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "5px"
}
export const CompanyHeaderCell = (props) => {
    
    return (
         <div style={headerStyles}>
            <TooltipHost 
        tooltipProps={{onRenderContent:()=>{
            return (<div>
                <div><span style={{fontWeight: "bold"}}>Status:</span> {props.customer.status}</div>
                <div><span style={{fontWeight: "bold"}}>Territory:</span> {props.customer.territory}</div>
            </div>);
        }}}>
       {props.customer.customerName}
        </TooltipHost>
        </div>
    )
}