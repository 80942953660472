import { Client, PendingRequest } from "./ApiClient";
import * as React from "react";
import * as ReactDOM from "react-dom"

import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';
import { Modal } from '@fluentui/react/lib/Modal';

import { AbsenceRequest } from "./AbsenceRequest"
const moment = require('moment');

interface IYourRequestState {
    requests: PendingRequest[],
    modalVisible: boolean,
    viewedItem: PendingRequest,
    rejectDialogVisible: boolean,

}
interface IYourRequestProps {
    viewHandled?(): void,
    viewId?: number,
}

const _columns = [
   
    { key: 'status', name: "Status", fieldName: "status", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'from', name: "From", fieldName: "absentFrom", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'to', name: "To", fieldName: "absentTo", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'hours', name: "Hours", fieldName: "numberOfHours", minWidth: 50, maxWidth: 50, isResizeable: true },
    { key: 'absencetype', name: 'Absence Type', fieldName: 'typeOfAbsence', minWidth: 150, maxWidth: 150, isResizeable: true },
    { key: 'reason', name: "Reason", fieldName: "reasonForAbsence", minWidth: 100, maxWidth: 300, isResizeable: true, isMultiline: true },
    { key: 'view', name: "", fieldName: "viewButton", minWidth: 20, maxWidth: 20, isResizeable: false },
];

export class YourRequests extends React.Component<IYourRequestProps, IYourRequestState> {
    constructor(props: {}) {
        super(props);
        
      
        this.state =
            {
                requests: new Array<PendingRequest>(),
                modalVisible: false,
                viewedItem: new PendingRequest(),
                rejectDialogVisible: false,

            };
        var client = new Client();
        // Handle the view of a supplied request id. 
       
      
        client.getYourRequests().then((data) => {
            this.setState({ requests: data });
            if (this.props.viewId) {
                if (this.props.viewHandled) {
                    this.props.viewHandled();
                }

                var res = data.find(req => req.id === this.props.viewId);
                if (res) {
                    this._showView(res);
                }

            }
        });
    }
    render() {
        const { requests } = this.state;
        return (
            <div>
                <h1>Prior Absence Requests</h1>
                <Modal isOpen={this.state.modalVisible}
                       onDismiss={this._closeView}
                       isBlocking={false}
                > <AbsenceRequest absenceRequest={this.state.viewedItem} /></Modal>
                
                <DetailsList items={requests}
                    columns={_columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    compact={true}
                    onRenderItemColumn={this._renderItemColumn}
                    selectionMode={SelectionMode.none}
                    onItemInvoked={this._onItemInvoked}
                    constrainMode={ConstrainMode.unconstrained}
                />
            </div>
        );
    }
    private _renderItemColumn = (item: any, index: number, column: IColumn): any => {
        const fieldContent = item[column.fieldName || ''];

    
        var vbId = "vb" + index;
        switch (column.key) {
            case 'to':
            case 'from':
                return <span>{moment(fieldContent).format("l")}</span>;
    
            case 'view':
                return <TooltipHost content="View request" id={vbId}>
                    <IconButton text="Reject" iconProps={{ iconName: 'RedEye' }} onClick={(): void => {
                        this._onView(item);
                    }} aria-describedby={vbId} /></TooltipHost>;
            default:
                return <span>{fieldContent}</span>;
        }

    }
    private _onItemInvoked = (item?: any, index?: number, ev?: Event): void | boolean => {
        if (item) {
            alert("Invoked " + item);
        }
    }
   
    private _onView = (item: PendingRequest): void => {
        this._showView(item);
    };

    private _showView = (item: PendingRequest): void => {
        this.setState({
            viewedItem: item,
            modalVisible: true
        });
    }
   
    private _closeView = (): void => {
        this.setState({ modalVisible: false });
    }
}