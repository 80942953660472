import react, { useState, useEffect } from "react";
import { DetailsList, SelectionMode } from "@fluentui/react";
import axios from "axios";
import { authProvider } from "../authProvider";

const rootUrl = window.location.origin + "/api/employeeforms/";
export const SubmissionResults = () => {

   
    const [statusItems, setStatusItems] = useState([]);
    const [token, setToken] = useState("");

    useEffect(()=>{
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(token=>tkn);
                axios.get(rootUrl + "submissionStatus",{headers: {"Authorization": "Bearer " + tkn}}  ).then((res)=>{
                    setStatusItems(res.data);
                });
            }
        });
    }, []);

    const columns = [
        {
            key: 'column1',
            name: 'Action',
            ariaLabel: 'Action Type: New Hire or Termination',
            fieldName: 'actionType',
            minWidth: 100,
            maxWidth: 100,

        },
        {
            key: 'column2',
            name: 'Employee',
            ariaLabel: 'New hire or terminated employee name',
            fieldName: 'employeeName',
            minWidth: 200,
            maxWidth: 200,

        },
        {
            key: 'column3',
            name: 'Date Submitted',
            ariaLabel: 'Date the form was submitted',
            fieldName: 'created',
            minWidth: 150,
            maxWidth: 150,

        },
        {
            key: 'column4',
            name: 'Last Updated',
            ariaLabel: 'Date the ticket was last updated',
            fieldName: 'lastUpdated',
            minWidth: 150,
            maxWidth: 150,
        },
        {
            key: 'column4',
            name: 'Submitted By',
            ariaLabel: 'User who submitted the form',
            fieldName: 'submittedBy',
            minWidth: 200,
            maxWidth: 200,
        }
    ];
    return (<div>
        <DetailsList items={statusItems} columns={columns} selectionMode={SelectionMode.none}/>
    </div>);
}