import "./RedGreenApp.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { TextField, Callout, PrimaryButton, DefaultButton, DirectionalHint } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter, ContextualMenu, Stack } from "@fluentui/react";
import { hiddenContentStyle, mergeStyles } from "@fluentui/react";
import { Direction } from "@syncfusion/ej2-react-charts";
import { useId } from '@fluentui/react-hooks'
import { authProvider } from "../authProvider";
import axios from 'axios';

function IsValidNumber(sourceStr) {
    // the string is valid if the string is a valid number and is less than 8 digits
    return !isNaN(parseInt(sourceStr)) && sourceStr.length < 11;
}

const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
};
const dialogStyles = { main: { maxWidth: 450 } };
const screenReaderOnly = mergeStyles(hiddenContentStyle);

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};
export const RedGreenApp = () => {
    const [searchText, setSearchText] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [token, setToken] = useState("");
    const [mouseOverCallout, setMouseOverCallout] = useState(false);
    const [canSearch, setCanSearch] = useState(true);
    const [isGreen, setIsGreen] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [isYellow, setIsYellow] = useState(true);
    const [opportunityAmount, setOpportunityAmount] = useState(null);
    const [hideDialog, setHideDialog] = useState(true);
    const result = null;
    const [noClicked, setNoClicked] = useState(false);
    const textFieldId = useId('search-field');
    const searchFieldRef = useRef(null);
    const calloutFieldRef = useRef(null);
  
    const rootUrl = "/api/RedGreen/";

    const labelId = useId('dialogLabel');
    const subTextId = useId('subTextLabel');

    const dialogContentProps = React.useMemo(() => ({
        type: DialogType.normal,
        title: 'Verify Opportunity Amount',
        closeButtonAriaLabel: 'Close',
        subText: `Does total revenue amount of ${formatCurrency(opportunityAmount)} match the amount in Sell?`,
    }), [opportunityAmount]);

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
            dragOptions: undefined,
        }),
        [labelId, subTextId],
    );

    // Get the access  token necessary to access endpoints on the server
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(tkn);
            }
        });
    }, [])


    // Originally intended to provice auto-completion of the opportunity number
    // The functionality was not very useful and has been disabled.
    useEffect(() => {
        if (!token) {
            return;
        }
        console.log(`searchText changed: ${searchText}`);
        if (!IsValidNumber(searchText)) {
            setSearchResults([]);
            setShowResults(false);
            return;
        }
        // console.log("searching for " + searchText);
        // axios.get(rootUrl + "searchOps/" + searchText, { headers: { "Authorization": "Bearer " + token } }).then(res => {
        //     console.log(res.data);
        //     if (res.data)

        //         setSearchResults(res.data);
        //         setShowResults(true);
        // });
    }, [searchText, token]);
    const handleClick = (result) => {
        setSearchText(result);
        setShowResults(false);
    };
    const onSearchChange = (event, newValue) => {
        resetState();
        setSearchText(newValue);
    };
    
    const onSearchFocus = () => {
        if (searchFieldRef.current) {
            searchFieldRef.current.focus();
            searchFieldRef.current.select();
        }
    }
    const onSearchBlur = () => {
        if (mouseOverCallout) {
            return;
        }
        setShowResults(false);
    }
    const onCalloutMouseEnter = () => {
        setMouseOverCallout(true);
    }
    const onCalloutMouseLeave = () => {
        setMouseOverCallout(false);
    }
    const resetState = () =>{
        setYellow()
        setNoClicked(false);
    }
    const setGreen = () =>{
        setIsGreen(true);
        setIsYellow(false);
        setIsRed(false);
    }
    const setYellow = ()=>{
        setIsGreen(false);
        setIsYellow(true);
        setIsRed(false);
    }
    const setRed = () =>{
        setIsGreen(false);
        setIsYellow(false);
        setIsRed(true);
    }
    // Check that the opportunity amount does not exceed the available credit
    const checkOpportunity = useCallback(() => {
        if (!token) {
            return;
        }

        if (!IsValidNumber(searchText)) {
            setSearchResults([]);
            setShowResults(false);
            return;
        }
        console.log("searching for " + searchText);
        axios.get(rootUrl + searchText, { headers: { "Authorization": "Bearer " + token } }).then(res => {
            console.log(res.data);
            if (res.data) {
                setGreen();
            } else {
                setRed();
            }

        });
    }, [searchText, token]);

    // Get the opportunity amount from the server
    const getOpportunityAmount = useCallback(async () => {
        setNoClicked(false);
        resetState();
        if (!token) { return; }
        if (!IsValidNumber(searchText)) {
            setSearchResults([]);
            setShowResults(false);
            return;
        }
        const res = await axios.get(rootUrl + "getOppAmount/" + searchText, { headers: { "Authorization": "Bearer " + token } })
        console.log(res.data);
        setOpportunityAmount(res.data);
        setHideDialog(false);

        return res.data;
    }, [searchText, token]);


    // Listen for the user to press Y or N to confirm or deny the dialog
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!hideDialog) {
                if (event.key === 'Y' || event.key === 'y') {
                    console.log("Y pressed")
                    // Confirm the dialog
                    onDialogYes();
                } else if (event.key === 'N' || event.key === 'n') {
                    // Cancel the dialog
                    console.log("N pressed")
                    onDialogNo();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hideDialog]);


    // The user has confirmed the amounts match
    const onDialogYes = () => {
        setNoClicked(false);
        setHideDialog(true);
        checkOpportunity();
    };

    // The user has stated the amounts do not match.
    const onDialogNo = () => {
        setHideDialog(true);
        setNoClicked(true);
        setIsGreen(false);
        setIsYellow(false);
        setIsRed(true);
    }

    return (
        <div>
            <h1>Red Light / Green Light Credit Verification</h1>
            <div className="instructions">
                <p>
                    Enter the opportunity number and click "Check".
                    You will be prompted to verify the amount matches the amount in Sell.
                    Upon clicking &quot;Yes&quot;, the system will check the available credit
                    and display the result. If the amount falls within the company's available credit,
                    the light will turn green.  If it does not it will turn red.
                </p>
                <p>
                    To check another opportunity, simply start typing the opportunity number and the light will reset.
                </p>
            </div>
            <div className="rgContainer">

                <div className="rgFormContainer">
                    <Stack tokens={{ childrenGap: 10 }}>
                        <TextField
                            id={textFieldId}
                            componentRef={searchFieldRef}
                            onChange={onSearchChange}
                            onFocus={onSearchFocus}
                            onBlur={onSearchBlur}
                            value={searchText}
                            label="Opportunity Number" />

                        <PrimaryButton text="Check" onClick={getOpportunityAmount} />
                    </Stack>
                    {showResults &&
                        <Callout
                            ComponentRef={calloutFieldRef}
                            DirectionalHint={DirectionalHint.bottomLeftEdge}
                            style={{
                                width: 320,
                                padding: '4px'
                            }}
                            target={`#${textFieldId}`}
                            onMouseEnter={onCalloutMouseEnter}
                            onMouseLeave={onCalloutMouseLeave} >
                            <ul className="suggestionList">
                                {searchResults.map((result, index) =>
                                    (<li key={`result_${index}`} onClick={() => handleClick(result)}>{result}</li>)
                                )}
                            </ul>
                        </Callout>}

                    {(isRed && !noClicked) &&
                        <div className="checkResult insufficient">
                            Available Credit is insufficient.
                        </div>
                    }
                    {isGreen &&
                        <div className="checkResult sufficient">
                            Available Credit is sufficient.
                        </div>
                    }
                    {noClicked &&
                        <div className="checkResult insufficient">
                            Please make sure the opportunity is updated to match the amount in Sell before checking again.
                        </div>}
                </div>

                <div className="rgSignalContainer">
                    <div className="trafficSignal">
                        <div className={`trafficLight ${isRed ? "red" : ""} `}></div>
                        <div className={`trafficLight ${isYellow ? "yellow" : ""}`}></div>
                        <div className={`trafficLight ${isGreen ? "green" : ""}`}></div>

                    </div>
                    <DialogFooter>

                    </DialogFooter>
                </div>

            </div>
            <Dialog
               
                hidden={hideDialog}
                onDismiss={() => setHideDialog(true)}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton text="Yes" onClick={onDialogYes} />
                    <DefaultButton text="No" onClick={onDialogNo} />
                </DialogFooter>
            </Dialog>
        </div >
    );

}