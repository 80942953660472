import React, { useState, useCallback, useEffect } from "react";
import { authProvider } from "../authProvider";
import axios from "axios";
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Label } from '@fluentui/react/lib/Label';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType, TextField } from "@fluentui/react";
import { Text } from "@fluentui/react";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
const memberStackStyles = { root: { background: DefaultPalette.themeLighter } };
const companyStackStyles = { root: { background: DefaultPalette.themeLighterAlt, paddingLeft: "40px" } };
const memberStackTokens = { childrenGap: 5, padding: 10 };
const companyStackTokens = { childrenGap: 5, padding: 5 }

const memberColumnStyle = { width: "175px", display: "inline-block" };
const companyStyle = { width: "150px", display: "inline-block" };
const removeColumnStyle = { display: "inline-block", width: "210px" };
const expandColumnStyle = { display: "inline-block", width: "141px", paddingRight: "5px" };

export const CompanyTeamCleaner = (params) => {
    const [token, setToken] = useState("");
    const [teamData, setTeamData] = useState(null);
    const [hasAccess, setHasAccess] = useState(false);
    const [messages, setMessages] = useState([]);
    const rootUrl = window.location.origin + "/api/CwTeamRemoval/";
    const [shownMembers, setShownMembers] = useState([]);

    const [confirmingRemove, setConfirmingRemove] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState(null);
    const [confirmText, setConfirmText] = useState("");
    const [confirmError, setConfirmError] = useState("");

    const toggleMember = useCallback((member) => {
        if (shownMembers.includes(member)) {
            setShownMembers(members => members.filter((item) => item != member));
        } else {
            setShownMembers(members => {
                var newMembers = [...members, member];
                return newMembers
            });
        }
    }, [shownMembers, setShownMembers]);
    const memberIsShown = useCallback((member) => {
        return shownMembers.includes(member);
    }, [shownMembers]);

    const addMessage = (message, messageBarType) => {
        setMessages(msgs => [...msgs, { message: message, messageBarType: messageBarType }]);
    }
    const removeMessage = (index) => {
        setMessages(msgs => msgs.filter((item, i) => i != index));
    }

    const onConfirmTextChange = useCallback((event, newValue) => {
        setConfirmText(confirmText => newValue);
    }, []);
    const onCleanupUser = useCallback((user) => {
        setMemberToRemove(member => user[1][0]);
        setConfirmingRemove(confirm => true);
    }, [token]);
    const onRemoveMember = useCallback(() => {
        if (confirmText == "REMOVE") {
            var memberInfo = memberToRemove;
            axios.get(rootUrl + "RemoveMemberFromAllTeams/" + memberInfo.memberRecId, { headers: { "Authorization": "Bearer " + token } }).then((result) => {
                if (result.data) {
                    addMessage("User " + memberInfo.memberName + " removed all company teams.", MessageBarType.success);
                } else {
                    addMessage("Error removing User \"" + memberInfo.memberName + "\" from company teams.", MessageBarType.error);
                }
                
                loadTeamData();
            }
            ).catch((error) => {
                addMessage(`Error while removing teams data. (${error.response.status})`, MessageBarType.error);
            });;
        } else {
            setConfirmError(c => "You must type \"REMOVE\" in uppoer case to confirm removal.");
        }
        setTeamData(data => null);
        setConfirmError(c => "")
        setConfirmingRemove(confirm => false);
        setMemberToRemove(member => null);
        setConfirmText(confirmText => "");
        setConfirmingRemove(confirm => null);
    }, [memberToRemove, confirmText]);
    const cancelRemoveMember = useCallback(() => {
        setConfirmError(c => "")
        setConfirmingRemove(confirm => false);
        setMemberToRemove(member => null);
        setConfirmText(confirmText => "");
        setConfirmingRemove(confirm => null);
        addMessage("User removal cancelled.", MessageBarType.info);
    }, []);
    const renderTeams = useCallback((() => {
        var result = Object.entries(teamData).map((item) => {
            var id = item[1][0].memberRecId;
            var comps = Array.from(new Set(item[1].map((company) => company.companyName)));
            return <Stack.Item >
                <div style={memberColumnStyle}><Text variant="large">{item[0]}</Text></div>
                <div style={companyStyle}><Text variant="mediumPlus">Companies: {item[1].length}</Text></div>
                <div style={expandColumnStyle}><DefaultButton onClick={() => { toggleMember(id) }}>
                    {memberIsShown(id) && <span>Hide Companies</span>}
                    {(!memberIsShown(id)) && <span>Show Companies</span>}
                </DefaultButton></div>
                <div style={removeColumnStyle}><PrimaryButton onClick={() => { onCleanupUser(item) }}>Remove from Teams</PrimaryButton></div>

                {shownMembers.includes(id) &&
                    <Stack tokens={companyStackTokens} styles={companyStackStyles} enableScopedSelectors>
                        {comps.map((company) => {

                            return <Stack.Item>{company}</Stack.Item>;
                        })}
                    </Stack>
                }
            </Stack.Item>;
        })
        return result;
    }), [teamData, shownMembers])
   
    const loadTeamData = useCallback((tkn)=>{
        const lTkn = tkn || token;
        axios.get(rootUrl + "GetMembersOnTeams", { headers: { "Authorization": "Bearer " + lTkn } }).then((result) => {
            setTeamData(data => result.data);
            //addMessage("Team data loaded.", MessageBarType.success);
        }).catch((error) => {
        
            addMessage(`Error loading data. (${error.response.status})`, MessageBarType.error);
        });
    }, [token]);
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(token => tkn);
               loadTeamData(tkn);
            }
        });
        authProvider.getIdToken().then((result) => {
            if (result.idToken.claims.roles.includes("CompanyTeamCleanup")) {
                setHasAccess(ha => true);
            }
        });
    }, []);
    return (

        <div>
            <Panel isOpen={confirmingRemove}
                onDismiss={cancelRemoveMember}
                type={PanelType.smallFixedFar}
                closeButtonAriaLabel="Cancel"
                headerText="Remove User From Company Teams?">
                {confirmingRemove &&
                    <div>
                        <Text variant="medium">This will permanently remove {memberToRemove.memberName} from all company teams and cannot be undone.
                            To Continue, you must type &quot;REMOVE&quot; to confirm the action.</Text>
                        <TextField label="Confirmation" value={confirmText} onChange={onConfirmTextChange}></TextField>
                        {confirmError != "" && <MessageBar messageBarType={MessageBarType.error}>{confirmError}</MessageBar>}

                        <PrimaryButton style={{marginTop:"10px"}} onClick={onRemoveMember} disabled={confirmText != "REMOVE"}>Remove User</PrimaryButton>

                    </div>
                }
            </Panel>
            <div style={{ position: "absolute", top: "45px", zIndex: 1000 }} >
                {messages.length > 0 &&
                    <Stack tokens={memberStackTokens} enableScopedSelectors>
                        {messages.map((item, ind) => {
                            return <MessageBar key={"msg" + ind} messageBarType={item.messageBarType} onDismiss={() => { removeMessage(ind) }} >{item.message}</MessageBar>;
                        })}
                    </Stack>}
            </div>
            {hasAccess &&
                <div>
                    <h1>Company Team Cleanup</h1>
                    {teamData == null &&
                        <div>
                            <Spinner label="Loading team data..." ariaLive="assertive" labelPosition="left" />
                        </div>
                    }
                    {teamData &&
                        <Stack tokens={memberStackTokens} styles={memberStackStyles}>
                            {renderTeams()}
                        </Stack>
                    }
                </div>
            }
            {(!hasAccess) &&
                <h2>You do not have access to this application.</h2>
            }
        </div>

    );
}
