import * as React from "react";
import * as ReactDOM from "react-dom";
import { loadTheme } from '@fluentui/react';
import { NettechTheme } from '../themes/NettechTheme.js'
import { getTheme, concatStyleSets } from '@fluentui/react/lib/Styling';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';
import { authConfig } from '../AbsenceRequestComponents/ApiClientConfig';
import { Client, FileParameter } from "../AbsenceRequestComponents/ApiClient";
import { HpSalesFileUpload } from "./HpSalesFileUpload";

export class HpSalesTrackerImporter extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        var self = this;

        loadTheme(NettechTheme);
        const theme = getTheme();

        this.state = {
            selectedFile: null,
            uploadEvents: { reset: () => { console.log("reset not initialized."); } }
        }
        this.onUpload = this.onUpload.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
    }
    onFileSelected(file) {
        this.setState({ selectedFile: file });
    }
    onUpload() {

        if (this.state.selectedFile) {
            let myFile = { data: this.state.selectedFile, fileName: this.state.selectedFile.name };

            var client = new Client();
            client.uploadSalesFile("", myFile).then((res) => {
                //var a = document.getElementById('downloadfile') as HTMLAnchorElement;
                //var file = new Blob([res.data], { type: res.headers['content-type'] });
                //a.href = URL.createObjectURL(file);
                //a.download = res.fileName;
                //a.dispatchEvent(new MouseEvent('click'));
                var suc = document.getElementById('isSuccessful');
                var message = document.getElementById('errorMessage');
                suc.textContent = res.isSuccessful ? "Successful" : "Error";
               

                console.log(res);
                /* console.log(res.fileName);*/
            });
        }
        this.state.uploadEvents.reset();

    }
    componentWillUpdate(nextProps: any, nextState: any) {
        // Added for debugging.
    }

    render() {
        var uploadDisabled = true;
        if (this.state.selectedFile) {
            uploadDisabled = false;
        }
        return (
            <div>
                <h1>HPE Sales Tracker File Import</h1>
                &nbsp;
                <Label>Use this to import HPE Sales Files into the Import Tracker Database</Label>
                <Label>The file must be in Excel (.xlsx) with no other modifications done to the file.</Label>
                <Label>Click the '...' button to select the file, then click 'Upload'.  The system will then process the file.</Label>
                <Label>Select file to upload:</Label>
                <HpSalesFileUpload onFileSelected={this.onFileSelected} accept=".xls,.xlsx" events={this.state.uploadEvents} />
                <PrimaryButton onClick={this.onUpload} text="Upload" disabled={uploadDisabled} />
                <a href="" id="downloadfile" style={{ display: 'none' }} ></a>
                <Label id="isSuccessful"></Label>
                <Label id="errorMessage"></Label>
            </div>
        );
    }
}