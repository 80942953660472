import React, { useState, useCallback, useEffect, useRef } from "react";
import { Pivot, PivotItem } from '@fluentui/react'
import { HeatMapSummary } from "./HeatMapSummary";
import { MasterHeatMap } from "./MasterHeatMap";
import { authProvider } from "../authProvider";
import { TechEditor } from "./TechEditor";
import { debounce } from 'lodash';
import axios from "axios";

function calculateAwareness(data, count) {
    var result = data.reduce((total, tech) => {
        if (tech.heatMapOptionId == 1 || tech.heatMapOptionId == 2 || tech.heatMapOptionId == 3) {
            total++;
        }
        return total;
    }, 0);
    return Math.floor((result / count) * 100);
}
function calculateAdoption(data, count) {
    var result = data.reduce((total, tech) => {
        if (tech.heatMapOptionId == 1) {
            total++;
        }
        return total;
    }, 0);
    return Math.floor((result / count) * 100);
}
function CalculateTechPenetration(techs, heatMapData) {
    var count = heatMapData.length;
    var results = techs.map((tech) => {
        var techTotal = heatMapData.reduce((total, cust) => {
            var custTech = cust.techs.find((el) => el.techId == tech.id);
            if (custTech && custTech.heatMapOptionId == 1) {
                total++;
            }
            return total;
        }, 0);
        return Math.floor((techTotal / count) * 100);
    });
    return results;
}

const exampleHeatMapData = [{
    customerName: "Acme Corp",
    companyId: "AcmeCorp",
    adoption: 26,
    awareness: 50,
    techs: [
        {
            techId: 1,
            name: "Core",
            heatMapOptionId: 1,
            value: "Customer"
        },
        {
            techId: 2,
            name: "Distributrion",
            heatMapOptionId: 1,
            value: "Customer"
        },
        {
            techId: 3,
            name: "Access",
            heatMapOptionId: 1,
            value: "Customer"
        }
    ]
}, {
    customerName: "ABC Inc",
    companyId: "ABCInc",
    adoption: 0,
    awareness: 100,
    techs: [{
        techId: 1,
        name: "Core",
        heatMapOptionId: 2,
        value: "Potential Opportunity"
    },
    {
        techId: 2,
        name: "Distribution",
        heatMapOptionId: 3,
        value: "No Opportunity"
    },
    {
        techId: 3,
        name: "Access",
        heatMapOptionId: 2,
        value: "Potential Opportunity"
    }]
}];
const averageTechPenetrationData = [
    { name: "Core", value: 43 },
    { name: "Distribution", value: 29 },
    { name: "Access", value: 57 }
];
const techList = [
    { id: 1, name: "Core", color: "#ccf2ff" },
    { id: 2, name: "Distribution", color: "#ccf2ff" },
    { id: 3, name: "Access", color: "#ccf2ff" },
    { id: 4, name: "Voice", color: "#99e6ff" },
]

const heatMapOptions = [
    { id: 1, name: "Customer", bgColor: "#ccf8cc", textColor: "#009900" },
    { id: 2, name: "Potential Opportunity", bgColor: "#fff9d0", textColor: "#b29a01" },
    { id: 3, name: "No Opportunity", bgColor: "#f7b1a9", textColor: "#bb2211" },
    { id: 4, name: "Don't Know", bgColor: "#ffffff", textColor: "#000000" }
]

export const SalesMasterHeatMapApp = (props) => {
    const rootUrl = window.location.origin + "/api/SalesHeatMap/"
    const [hmState, setHmState] = useState({ heatMapData: exampleHeatMapData, techs: techList, heatMapOptions: heatMapOptions, techPenetrations: [] })
    const [calculationsRun, setCalculationsRun] = useState(false);
    const token = useRef();
    const [canWrite, setCanWrite] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const tList = techList;
    // load companies

    // load company mapping data
    // perform calculations
    const recalculateData = useCallback(() => {
        if (calculationsRun) {
            return;
        }
        var hmd = hmState.heatMapData;
        setCalculationsRun(true);
        const techPenetrations = CalculateTechPenetration(hmState.techs, hmState.heatMapData);
        hmd.map((cust) => {
            cust.adoption = calculateAdoption(cust.techs, hmState.techs.length);
            cust.awareness = calculateAwareness(cust.techs, hmState.techs.length);
        });
        setHmState({ ...hmState, techPenetrations: techPenetrations, heatMapData: hmd })


    }, [hmState, setHmState, calculationsRun]);

    recalculateData();
    const loadCompanyData = useCallback((tkn) => {
        const lTkn = tkn;
        axios.get(rootUrl + "GetCompanyData", { headers: { "Authorization": "Bearer " + lTkn } }).then((result) => {
            setHmState(state => { return { ...state, heatMapData: result.data } });
            setCalculationsRun(run => false);
        }).catch((error) => {
            console.log(`Error loading data. (${error.response.status})`);
        });
    }, [setHmState]);
    const loadOptions = useCallback((tkn) => {
        const lTkn = tkn;
        axios.get(rootUrl + "GetOptions", { headers: { "Authorization": "Bearer " + lTkn } }).then((result) => {
            setHmState(state => { return { ...state, heatMapOptions: result.data } });
            loadCompanyData(lTkn);
        }).catch((error) => {
            console.log(`Error loading data. (${error.response.status})`);
        });
    }, [setHmState, loadCompanyData]);
    const loadTechs = useCallback((tkn) => {
        const lTkn = tkn;
        axios.get(rootUrl + "GetTechs", { headers: { "Authorization": "Bearer " + lTkn } }).then((result) => {
            setHmState(state => { return { ...state, techs: result.data } });
            loadOptions(lTkn);
            //addMessage("Team data loaded.", MessageBarType.success);
        }).catch((error) => {

            console.log(`Error loading data. (${error.response.status})`);
        });
    }, [setHmState, loadOptions]);





    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                token.current = tkn;
                loadTechs(tkn);
            }
        });
        authProvider.getIdToken().then(result => {
            if (result.idToken.claims.roles.includes("SalesHeatMap.Write") || result.idToken.claims.roles.includes("SalesHeatMap.Admin")) {
                setCanWrite(canWrite => true);
            } else {
                setCanWrite(canWrite => false);
            }
            if (result.idToken.claims.roles.includes("SalesHeatMap.Admin")) {
                setIsAdmin(isAdmin => true);
            }
        });
    }, []);

    useEffect(() => {
        if (calculationsRun) {
            return;
        }
        recalculateData();

    }, [calculationsRun, recalculateData]);

    const onHeatMapItemChange = useCallback((optionId, techId, companyId) => {
        axios.post(rootUrl + "UpdateItem", { optionId: optionId, techId: techId, companyId: companyId }, { headers: { "Authorization": "Bearer " + token.current } }).then((result) => {
            if (result.data) {
                var heatMapData = hmState.heatMapData;
                var company = heatMapData.find((el) => el.companyId == companyId);
                if (!company) {
                    return;
                }
                var tech = company.techs.find((el) => el.techId == techId);
                if (!tech) {
                    var techItem = hmState.techs.find((el) => el.id == techId);
                    tech = {
                        techId: techId,
                        name: techItem.name,
                        heatMapOptionId: optionId,
                        value: hmState.heatMapOptions.find((el) => el.id == optionId).name
                    }
                    company.techs.push(tech);
                }
                tech.heatMapOptionId = optionId;
                setHmState({ ...hmState, heatMapData: heatMapData });
                setCalculationsRun(false);
            }
        }).catch((error) => {
            console.log(`Error updating item. (${error.response.status})`);
        });
    }, [hmState,  setHmState, setCalculationsRun]);

    const updateTech = (tech) => {
        axios.post(rootUrl + "UpdateTech", tech, { headers: { "Authorization": "Bearer " + token.current } }).then((result) => {
            if (result.data) {

            }
        }).catch((error) => {
            if (error.response.status == 401) {
                alert("You are not authorized to update techs!");
            }
            console.log(`Error updating tech. (${error.response.status})`);
        });
    };

    const debouncedUpdateTech = React.useRef(debounce(updateTech, 500)).current;

    const onUpdateTech = useCallback((newTech) => {
        var techs = hmState.techs;
        var tech = techs.find((el) => el.id == newTech.id);
        if (tech) {
            tech.name = newTech.name;
            tech.color = newTech.color;
            tech.order = newTech.order;
            techs.sort((a, b) => a.order - b.order);
            setHmState({ ...hmState, techs: techs });
        }
        debouncedUpdateTech(newTech);
    }, [hmState, setHmState]);

    const onBatchUpdateTechs = useCallback((techs) => {
        axios.post(rootUrl + "BatchUpdateTechs", techs, { headers: { "Authorization": "Bearer " + token.current } }).then((result) => {
            if (result.data) {
                var techs = hmState.techs;
                result.data.map(newTech => {
                    var tech = techs.find((el) => el.id == newTech.id);
                    if (tech) {
                        tech.name = newTech.name;
                        tech.color = newTech.color;
                        tech.order = newTech.order;
                    }
                    else {
                        techs.push(result.data);
                    }
                });
                techs.sort((a, b) => a.order - b.order);
                setHmState({ ...hmState, techs: techs })
            }

        }).catch((error) => {
            if (error.response.status == 401) {
                alert("You are not authorized to update techs!");
            }
            console.log(`Error updating tech. (${error.response.status})`);
        });
    }, [hmState, setHmState]);

    const onNewTech = useCallback(() => {
        axios.get(rootUrl + "NewTech", { headers: { "Authorization": "Bearer " + token.current } }).then((result) => {

            var techs = hmState.techs;
            techs.push(result.data);
            techs.sort((a, b) => a.order - b.order);
            setHmState({ ...hmState, techs: techs });
        }).catch((error) => {
            if (error.response.status == 401) {
                alert("You are not authorized to update techs!");
            }
            console.log(`Error updating tech. (${error.response.status})`);
        });
    }, [hmState, setHmState ]);
    const onDeleteTech = useCallback((tech) => {
        axios.delete(rootUrl + "DeleteTech/" + tech.id, { headers: { "Authorization": "Bearer " + token.current } }).then((result) => {
            if (result.data) {
                var techs = hmState.techs;
                var index = techs.indexOf(tech);

                for (var i = index + 1; i < techs.length; i++) {
                    techs[i].order = techs[i].order - 1;
                }
                if (index > -1) {
                    techs.splice(index, 1);
                }
                hmState.heatMapData.map((cust) => {
                    var techIndex = cust.techs.findIndex((el) => el.techId == tech.id);
                    if (techIndex > -1) {
                        cust.techs.splice(techIndex, 1);
                    }
                });

                techs.sort((a, b) => a.order - b.order);
                setHmState({ ...hmState, heatMapData: hmState.heatMapData, techs: techs });
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                alert("You are not authorized to update techs!");
            }
            console.log(`Error updating tech. (${error.response.status})`);
        });
    }, [hmState, setHmState]);
    return (<div>
        <h1>Client Master Heat Map</h1>
        <Pivot aria-label="Master Heat Map Tabs">
            <PivotItem headerText="Heat Map">
                <MasterHeatMap heatMapState={hmState} onOptionChange={onHeatMapItemChange} canWrite={canWrite} />
            </PivotItem>
            <PivotItem headerText="Summary">
                <HeatMapSummary heatMapState={hmState} />
            </PivotItem>
            {isAdmin && <PivotItem headerText="Edit Technologies">
                <TechEditor techs={hmState.techs} onUpdateTech={onUpdateTech} onBatchUpdate={onBatchUpdateTechs} onNew={onNewTech} onDelete={onDeleteTech} />
            </PivotItem>}
        </Pivot>

    </div>)
}