import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { Client } from "../AbsenceRequestComponents/ApiClient";
import { authConfig } from '../AbsenceRequestComponents/ApiClientConfig';
import { authProvider } from '../authProvider';
import { PrimaryButton } from "@fluentui/react";
export const DocumentAcceptance = () => {
    let { id } = useParams();
    const [docRead, setDocRead] = useState(false);
    const [currModel, setCurrModel] = useState(null);
    const [token, setToken] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const [alreadyAccepted, setAlreadyAccepted] = useState(false);
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(tkn);
                setAuthenticated(true);
            }
            var client = new Client()
            client.documentAcceptance(id).then((res) => {
                if (res.fileHash == res.lastAcceptedFileHash) {
                    setAlreadyAccepted(true)
                }
                setCurrModel(res);
            });
        });
    }, [])
    function onAccept(){
        var client = new Client();
        client.approve(id).then((res)=>{
            if (res.fileHash == res.lastAcceptedFileHash) {
                setAlreadyAccepted(true)
            }
            setCurrModel(res);
        });
    }

    return (
        <div>
            {currModel &&
                <div>

                    <h2>Please Read and accept the {currModel.documentName}</h2 >


                    <iframe id="documentFrame" src={"/documents/" + currModel.fileName + "#toolbar=0"} style={{ width: "100%", height: "600px" }}></iframe>

                    {alreadyAccepted == false &&
                        <div>
                            <p>By clicking &quot;Accept&quot; I, <span style={{fontWeight: "bold"}}>{currModel.fullName}</span>, certify that I have read and agree to the preceding document.</p>
                            <PrimaryButton onClick={onAccept}>Accept</PrimaryButton>
                           
                        </div>
                        
                    }
                    {alreadyAccepted == true &&
                        <p>You accepted this document on {currModel.lastAcceptedDateTime}.  </p>
                    }   
                    <a href={"/documents/" + currModel.fileName} download>Download {currModel.documentName}</a>
                </div>
            }
            {(!currModel) &&
                <h2>Loading... Please Wait.</h2>
            }
        </div>)
}