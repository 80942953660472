import { Client, PendingRequest } from "./ApiClient";
import * as React from "react";

import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, ConstrainMode } from '@fluentui/react/lib/DetailsList';

import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { IconButton } from '@fluentui/react/lib/Button';
import { Modal } from '@fluentui/react/lib/Modal';

import { AbsenceRequest } from "./AbsenceRequest"
import { Checkbox } from '@fluentui/react/lib/Checkbox';
const moment = require('moment');

interface IApprovedRequestState {
    requests: PendingRequest[],
    modalVisible: boolean,
    viewedItem: PendingRequest,
    rejectDialogVisible: boolean,
    includeHandledRequests: boolean,

}
interface IApprovedRequestProps {
    viewHandled?(): void,
    viewId?: number,
}

const _columns = [
    { key: 'column1', name: "Employee", fieldName: "employeeName", minWidth: 100, maxWidth: 100, isResizeable: true },
    { key: 'status', name: "Status", fieldName: "status", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'from', name: "From", fieldName: "absentFrom", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'to', name: "To", fieldName: "absentTo", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'hours', name: "Hours", fieldName: "numberOfHours", minWidth: 50, maxWidth: 50, isResizeable: true },
    { key: 'absencetype', name: 'Absence Type', fieldName: 'typeOfAbsence', minWidth: 150, maxWidth: 150, isResizeable: true },
    { key: 'reason', name: "Reason", fieldName: "reasonForAbsence", minWidth: 100, maxWidth: 300, isResizeable: true, isMultiline: true },
    { key: 'approvalDate', name: "Approved On", fieldName: "approvalDate", minWidth: 75, maxWidth: 75, isResizeable: true },
    { key: 'addedToCal', name: "On Cal", fieldName: "addedToCalendar", minWidth: 50, maxWidth: 50, isResizeable: true },
    { key: 'view', name: "", fieldName: "viewButton", minWidth: 20, maxWidth: 20, isResizeable: false },
    { key: 'added', name: "", fieldName: "addedButton", minWidth: 20, maxWidth: 20, isResizeable: false }
];

export class ApprovedRequests extends React.Component<IApprovedRequestProps, IApprovedRequestState> {
    constructor(props: {}) {
        super(props);


        this.state =
            {
                requests: new Array<PendingRequest>(),
                modalVisible: false,
                viewedItem: new PendingRequest(),
                rejectDialogVisible: false,
                includeHandledRequests: false
            };
        var client = new Client();
        // Handle the view of a supplied request id. 
        client.getApprovedRequests(this.state.includeHandledRequests).then((data) => {
            this.setState({ requests: data });
            if (this.props.viewId) {
                if (this.props.viewHandled) {
                    this.props.viewHandled();
                }

                var res = data.find(req => req.id === this.props.viewId);
                if (res) {
                    this._showView(res);
                }

            }
        });
    }
    render() {
        const { requests } = this.state;
        return (
            <div>
                <h1>Approved Absence Requests</h1>
                <Checkbox label="Include requests that have already been added to the calendar?" checked={this.state.includeHandledRequests} onChange={this._onIncludeApprovedChanged} />
                <Modal isOpen={this.state.modalVisible}
                    onDismiss={this._closeView}
                    isBlocking={false}
                > <AbsenceRequest absenceRequest={this.state.viewedItem} /></Modal>

                <DetailsList items={requests}
                    columns={_columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    compact={true}
                    onRenderItemColumn={this._renderItemColumn}
                    selectionMode={SelectionMode.none}
                    onItemInvoked={this._onItemInvoked}
                    constrainMode={ConstrainMode.unconstrained}
                />
            </div>
        );
    }
    private _renderItemColumn = (item: any, index: number, column: IColumn): any => {
        const fieldContent = item[column.fieldName || ''];


        var vbId = "vb" + index;
        var abId = "ab" + index;
        switch (column.key) {
            case 'to':
            case 'from':
            case 'approvalDate':
                return <span>{moment(fieldContent).format("l")}</span>;

            case 'view':
                return <TooltipHost content="View request" id={vbId}>
                    <IconButton text="Reject" iconProps={{ iconName: 'RedEye' }} onClick={(): void => {
                        this._onView(item);
                    }} aria-describedby={vbId} /></TooltipHost>;

            case 'added':
                return <TooltipHost content="Mark request as added to calendar." id={abId}>
                    <IconButton text="Added To Calendar" iconProps={{ iconName: 'AddEvent' }} onClick={(): void => {
                        this._markAdded(item);
                    }} aria-describedby={abId} />
                </TooltipHost>;

            case 'addedToCal':
                return <Checkbox checked={item.addedToCalendar} disabled={true} />;
            default:
                return <span>{fieldContent}</span>;
        }

    }
    private _onItemInvoked = (item?: any, index?: number, ev?: Event): void | boolean => {
        if (item) {
            alert("Invoked " + item);
        }
    }

    private _onView = (item: PendingRequest): void => {
        this._showView(item);
    };

    private _markAdded = (item: PendingRequest): void => {
        var client = new Client();
        var items = this.state.requests;
        client.addedToCalendar(item.id).then((success) => {
            if (success) {
                var added = items.find(itm => itm.id == item.id);
                if (added) {
                    var index = items.indexOf(added);
                    if (!this.state.includeHandledRequests) {
                        items = items.filter(obj => obj.id !== added.id);
                    } else {
                        added.addedToCalendar = true;
                    }
                    this.setState({ requests: items });
                }
            }
        });
    }

    private _showView = (item: PendingRequest): void => {
        this.setState({
            viewedItem: item,
            modalVisible: true
        });
    }

    private _closeView = (): void => {
        this.setState({ modalVisible: false });
    }

    private _reloadRequests = (): void => {
        var client = new Client();
        client.getApprovedRequests(this.state.includeHandledRequests).then((data) => {
            this.setState({ requests: data });
        });
    }

    private _onIncludeApprovedChanged = (ev: React.FormEvent<HTMLElement>, isChecked: boolean): void => {
        this.setState({ includeHandledRequests: isChecked });
        this._reloadRequests();
    }
}