import React from 'react';

export const AveragePenetrationCell = (props) => {
    function GetGradientColor(value){
        var red = 0;
        var green = 0;
        var blue = 0;
        if (value < 50){
            red = 255;
            green = Math.round(5.1 * value);
        } else {
            green = 255;
            red = Math.round(510 - 5.10 * value);
        }
        var result = `rgb(${red},${green},${blue})`;
        return result;
    }
    const bgColor = GetGradientColor(props.value);
    const style = {
        width: "175px",
        minWidth: "175px",
        color: props.color || "#000000",
        backgroundColor: bgColor,
        borderColor: "#000000",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px"
    };
    return (<div style={style}>{props.value}%</div>);
}