import * as React from "react";
import * as ReactDOM from "react-dom";
import { loadTheme } from '@fluentui/react';
import { NettechTheme } from '../themes/NettechTheme.js'
import { getTheme, concatStyleSets } from '@fluentui/react/lib/Styling';
import { authConfig } from '../AbsenceRequestComponents/ApiClientConfig';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';

export class HpSalesFileUpload extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        var self = this;

        loadTheme(NettechTheme);
        const theme = getTheme();

        this.state = {
            selectedFile: "",
            fileRef: React.createRef()
        }
        this.onFileChange = this.onFileChange.bind(this);
        this.onReset = this.onReset.bind(this);

    }
    onFileChange(event) {


        if (this.props.onFileSelected && event.target.files && event.target.files[0]) {
            this.setState({ selectedFile: event.target.files[0].name });
            this.props.onFileSelected(event.target.files[0]);
        }
        else {
            this.setState({ selectedFile: "" });
            if (this.props.onFileSelected) {
                this.props.onFileSelected(null);
            }
        }
    }
    onReset() {
        console.log("onUploaded in FileUpload.");
        this.state.fileRef.current.value = "";
        this.setState({ selectedFile: "" });
        if (this.props.onFileSelected) {
            this.props.onFileSelected(null);
        }
    }

    componentWillUpdate(nextProps: any, nextState: any) {

    }
    render() {
        if (this.props.events) {
            console.log("Initializing events.");
            this.props.events.reset = this.onReset;
        }
        var acceptProp = {}
        if (this.props.accept) {
            acceptProp = { accept: this.props.accept }
        }
        return (
            <div>
                <input style={{ display: "none" }} type="file" onChange={this.onFileChange} {...acceptProp} ref={this.state.fileRef} />
                <Stack horizontal>
                    <Stack.Item grow={1}>
                        <TextField style={{ minWidth: "176px", maxWidth: "400px" }} value={this.state.selectedFile} readOnly />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton style={{ width: "16px", minWidth: "16px" }} text="..." onClick={() => { this.state.fileRef.current.click() }} />
                    </Stack.Item>
                </Stack>
            </div>
        );
    }

}