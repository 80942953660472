import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { authProvider } from '../authProvider';
import axios from "axios";
import { Stack, Label, Text, DatePicker, defaultDatePickerStrings, TimePicker, PrimaryButton, TextField, CommandBar, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';

const rootUrl = window.location.origin + "/api/EstimateActionPlan/";
const textFieldStyles = { padding: "5px" }
const taskBorderStyle = { borderWidth: "1px", borderStyle: "solid", borderColor: "black", padding: "5px", borderRadius: "5px", marginBottom: "5px" }
const stackTokens = { childrenGap: 10 }
const commandBarStyle = { position: "fixed", top: "75px", right: "40px", width: "250px", zIndex: 1000, borderColor: "rgb(213, 212, 211)", borderStyle: "solid", borderWidth: "1px", borderRadius: "5px" }

export const TaskEditor = (props) => {
    let { id } = useParams();
    const [token, setToken] = useState("")
    const [actionPlan, setActionPlan] = useState({});
    const [commandbarItems, setCommandbarItems] = useState([]);
    const [hideDialog, toggleHideDialog] = useBoolean(true);
    const [dialogText, setDialogText] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(token => tkn);
            }
        });

    }, []);

    function MergeDate(date, time) {
        var newDate = new Date();
        if (date) {
            newDate = new Date(date);
        }
        if (time) {
            newDate.setHours(time.getHours());
            newDate.setMinutes(time.getMinutes());
        }
        return newDate;
    }
    function formatDate(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
    }

    useEffect(() => {
        if (token && id) {
            axios.get(rootUrl + "getActionPlan/" + id, { headers: { "Authorization": "Bearer " + token } }).then((result) => {
                for (var i = 0; i < result.data.tasks.length; i++) {
                    if (result.data.tasks[i].startDate) {
                        result.data.tasks[i].startDate = new Date(result.data.tasks[i].startDate);
                    }
                    if (result.data.tasks[i].endDate) {
                        result.data.tasks[i].endDate = new Date(result.data.tasks[i].endDate);
                    }
                }
                setActionPlan(result.data);
            });
        }
    }, [token, id]);

    const onSaveActionPlan = useCallback(() => {
        axios.post(rootUrl + "saveActionPlan", actionPlan, { headers: { "Authorization": "Bearer " + token } }).then((result) => {
            for (var i = 0; i < result.data.tasks.length; i++) {
                if (result.data.tasks[i].startDate) {
                    result.data.tasks[i].startDate = new Date(result.data.tasks[i].startDate);
                }
                if (result.data.tasks[i].endDate) {
                    result.data.tasks[i].endDate = new Date(result.data.tasks[i].endDate);
                }
            }
            setActionPlan(() => { return result.data; });
            setDialogText("Action Plan Saved");
            setDialogTitle("Save Successful");
            toggleHideDialog.setFalse();
        }).catch((err) => {
            if (err && err.response && err.response.status == 423) {

                setDialogText("File could not be saved to sharepoint because it was locked.  Changes to the action plan were not saved."
                    + " Please close any documents you have open.  If you do not have any open, open the document and close it to attempt to release the lock."
                    + " If the document still won't save, make sure no one else has it open and try again.  If you still can't save, contact development for assistance.");
                setDialogTitle("Error Saving Action Plan");
                toggleHideDialog.setFalse();
            }
            else {
                var msg = "A " + err.response.status + " error occurred while saving the action plan: ";
                if (err.response.data && err.response.data.detail) {
                    msg = msg + err.response.data.detail;
                }
                else {
                    msg = msg + "Unknown Error."
                }
                setDialogText(msg);
                setDialogTitle("Error Saving Action Plan");
                toggleHideDialog.setFalse();
            }
            console.log(err);
        });
    }, [actionPlan, setActionPlan]);

    useEffect(() => {
        if (!actionPlan) {
            setCommandbarItems(() => { return [] });
            return;
        }
        if (actionPlan.documentUrl) {
            setCommandbarItems(() => {
                return [
                    { key: "back", text:"Return to List", iconProps: { iconName: "List" }, href: "/ProjectActionPlan" },
                    { key: "save", text: "Save", iconProps: { iconName: 'save' }, onClick: onSaveActionPlan },
                    { key: "viewDocument", text: "View Document", iconProps: { iconName: "view" }, href: actionPlan.documentUrl, target: "_blank" }
                ]
            });
        }
        else {
            setCommandbarItems(() => {
                return [
                    { key: "back", text:"Return to List", iconProps: { iconName: "List" }, href: "/ProjectActionPlan", target: "_blank" },
                    { key: "save", text: "Save", iconProps: { iconName: 'save' }, onClick: onSaveActionPlan }];
            })
        }

    }, [actionPlan, onSaveActionPlan]);
    // These must be declared after onSaveActionPlan


    const onSelectStartDate = useCallback((newDate, task) => {
        setActionPlan((actionPlan) => {
            var ap = { ...actionPlan };
            var tsk = ap.tasks.find((t) => {
                return t.taskNumber === task.taskNumber
            });
            tsk.startDate = MergeDate(newDate, tsk.startDate);
            return ap;
        });
    }, [setActionPlan]);
    const onSelectStartTime = useCallback((newTime, task) => {
        setActionPlan((actionPlan) => {
            var ap = { ...actionPlan };
            var tsk = ap.tasks.find((t) => t.taskNumber === task.taskNumber);
            tsk.startDate = MergeDate(task.startDate, newTime);
            return ap;
        });
    }, [setActionPlan]);

    const onSelectEndDate = useCallback((newDate, task) => {
        setActionPlan((actionPlan) => {
            var ap = { ...actionPlan };
            var tsk = ap.tasks.find((t) => t.taskNumber === task.taskNumber);
            tsk.endDate = MergeDate(newDate, tsk.endDate);
            return ap;
        });
    }, [setActionPlan]);

    const onSelectEndTime = useCallback((newTime, task) => {
        setActionPlan((actionPlan) => {
            var ap = { ...actionPlan };
            var tsk = ap.tasks.find((t) => t.taskNumber === task.taskNumber);
            tsk.endDate = MergeDate(tsk.endDate, newTime);
            return ap;
        });
    }, [setActionPlan]);

    const onNotesChange = useCallback((task, newVal) => {
        setActionPlan((actionPlan) => {
            var ap = { ...actionPlan };
            var tsk = ap.tasks.find((t) => t.taskNumber === task.taskNumber);
            tsk.notes = newVal;
            return ap;
        });
    }, [setActionPlan]);

    const onResourceChange = useCallback((task, newVal) => {
        setActionPlan((actionPlan) => {
            var ap = { ...actionPlan };
            var tsk = ap.tasks.find((t) => t.taskNumber === task.taskNumber);
            tsk.resource = newVal;
            return ap;
        });
    }, [setActionPlan]);

    return (
        <div>
            <h2>Project Action Plan</h2>
            <h3>Editing Action Plan</h3>
            {actionPlan.quoteNumber &&
                <div>
                    <div style={commandBarStyle}>
                        <CommandBar items={commandbarItems} />
                    </div>
                    <Stack horizontal>
                        <Label>Quote Number:</Label> <Text style={textFieldStyles} >{actionPlan.quoteNumber}</Text>
                        <Label>Customer:</Label> <Text style={textFieldStyles}>{actionPlan.company}</Text>
                    </Stack>
                    <Stack horizontal><Label>Title:</Label><Text style={textFieldStyles}>{actionPlan.projectTitle}</Text></Stack>
                    <Stack horizontal><Label>Description:</Label><Text style={textFieldStyles}>{actionPlan.jobDescription}</Text></Stack>
                    {actionPlan.documentUrl &&
                        <div><a href={actionPlan.documentUrl} target="_blank">View Document</a></div>
                    }
                    <h4>Tasks</h4>
                    {actionPlan.tasks.map((task) => {
                        return (
                            <div key={"tk" + task.taskNumber} style={taskBorderStyle}>
                                <Stack horizontal>
                                    <Label>{task.taskNumber} - {task.description} Estimated Hours: {task.estimatedHours}</Label>
                                </Stack>
                                <Stack horizontal tokens={stackTokens}>
                                    <DatePicker
                                        label="Start Date"
                                        value={task.startDate}
                                        onSelectDate={(newDate) => {
                                            onSelectStartDate(newDate, task)
                                        }}
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                    />
                                    <TimePicker
                                        label="Start Time"
                                        //dateAnchor={task.startDate}
                                        useHour12
                                        value={task.startDate}
                                        onChange={(_, newTime) => {
                                            console.log("tk" + task.taskNumber + " start time changed");
                                            onSelectStartTime(newTime, task);
                                        }}
                                    />
                                    <DatePicker
                                        label="End Date"
                                        value={task.endDate}
                                        onSelectDate={(newDate) => { onSelectEndDate(newDate, task) }}
                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                    />
                                    <TimePicker
                                        //dateAnchor={task.endDate}
                                        label="End Time"
                                        value={task.endDate}
                                        useHour12
                                        onChange={(_, newTime) => { onSelectEndTime(newTime, task) }}
                                    />
                                </Stack>
                                <div style={{ marginBottom: "10px" }}>
                                    <TextField label="Notes" multiline value={task.notes} onChange={(evt, newVal) => { onNotesChange(task, newVal) }} />
                                    <TextField label="Resource" value={task.resource} onChange={(evt, newVal) => { onResourceChange(task, newVal) }} />
                                </div>

                            </div>

                        )
                    })}
                </div>
            }
            <Dialog hidden={hideDialog}
                onDismiss={toggleHideDialog.setTrue}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogTitle,
                    closeButtonAriaLabel: 'Close',
                    subText: dialogText,
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog.setTrue} text="Ok" />
                </DialogFooter>

            </Dialog>
        </div>);
}
