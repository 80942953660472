import React from "react";

export const HeadingCell = (props) => {
    const style = {
        width: props.width || "202px",
        minWidth: props.width || "200px",
        color: props.color || "#000000",
        backgroundColor: props.backgroundColor || "#cccccc",
        borderBottomColor: "#000000",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        fontWeight: "bold",
        padding: "5px"
    };
    if (props.height) style.height = props.height;
    return (<div style={style}>
        {props.text}
    </div>);
}