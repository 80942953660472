import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Stack, TextField, ColorPicker, DefaultButton, Modal, IconButton } from '@fluentui/react'
import { debounce } from 'lodash';
export const TechEditor = (props) => {

    const [techs, setTechs] = useState(props.techs);
    const [openColorPicker, setOpenColorPicker] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(0);
    const [addingTech, setAddingTech] = useState(false);
    const addButtonRef = useRef();

    useEffect(() => {
        setTechs(props.techs);

    }, [props.techs]);

    const onColorChange = useCallback((tech, color) => {
        var nTech = { ...tech };
        nTech.color = color.str;
        //var nTechs = 
        //setTechs(lTechs=>{return lTechs.map((t)=>{return t.id === nTech.id ? nTech : t;})});
        if (props.onUpdateTech) {
            props.onUpdateTech(nTech);
        }
    }, [props.onUpdateTech])

    const onNameChange = useCallback((tech, name) => {
        tech.name = name;
        props.onUpdateTech(tech);
    }, [props.onUpdateTech]);

    

    const onMoveTechUp = useCallback((tech) => {
        var index = techs.indexOf(tech);
        if (index > 0) {
            var prevTech = techs[index - 1];
            prevTech.order = tech.order;
            tech.order = tech.order - 1;
            props.onBatchUpdate([prevTech, tech]);
        }
    }, [props.onUpateTech]);

    const onMoveTechDown = useCallback((tech) => {
        var index = techs.indexOf(tech);
        if (index < techs.length - 1) {
            var nextTech = techs[index + 1];
            nextTech.order = tech.order;
            tech.order = tech.order + 1;
            props.onBatchUpdate([nextTech, tech]);
        }
    }, [props.onMoveTechDown]);

    const onDeleteTech = useCallback((tech) => {
        props.onDelete(tech);
    }, [props.onDelete]);

    const onNewTech = useCallback(() => {
        props.onNew();

        setTimeout(() => {

            addButtonRef.current.scrollIntoView({ behavior: "smooth" });
        }, 500);


    }, [props.onNew]);


    return (<div>

        <div style={{marginTop: "10px", marginBottom: "10px"}}>
            <DefaultButton text="Add New Technology" onClick={onNewTech} />
        </div>
        <Stack tokens={{ childrenGap: 10 }}>
            {techs.map((tech) => {
                return (
                    <div key={"tedt_" + tech.id} style={{ backgroundColor: tech.color, borderWidth: "1px", borderStyle: 'solid', padding: "4px", borderRadius: "10px" }}>
                        <h2>{tech.name}</h2>
                        <div><TextField label="Technology Name" value={tech.name} onChange={(evt, value) => { onNameChange(tech, value) }} /></div>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <IconButton title="Change Color" iconProps={{ iconName: "color" }} onClick={() => { setOpenColorPicker(tech.id) }} />
                            <IconButton title="Move Up" iconProps={{ iconName: "up" }} onClick={() => { onMoveTechUp(tech); }} />
                            <IconButton title="Move Down" iconProps={{ iconName: "down" }} onClick={() => { onMoveTechDown(tech); }} />
                            <IconButton title="Delete Tech" iconProps={{ iconName: "delete" }} onClick={() => {setOpenDeleteDialog(tech.id) }} />
                        </Stack>

                        <Modal isOpen={tech.id == openColorPicker}

                            onDismiss={() => { setOpenColorPicker(0); }}

                        >
                            <div>
                                <ColorPicker
                                    color={tech.color}
                                    onChange={(evt, newValue) => { onColorChange(tech, newValue); }}
                                    alphaType='none'

                                />
                                <DefaultButton text='Done' onClick={() => { setOpenColorPicker(0); }} />

                            </div>
                        </Modal>
                        <Modal isOpen={tech.id == openDeleteDialog} onDismiss={() => { setOpenDeleteDialog(0); }} style={{borderRadius: "10px"}}>
                            <div style={{padding: "10px", }}>
                                <h2>Are you sure you want to delete {tech.name}?</h2>
                                <p>This will permanently delete all data associated with the technology, and cannot be undone.</p>
                                <DefaultButton text="Cancel" onClick={() => { setOpenDeleteDialog(0); }} />
                                <DefaultButton text="Delete" onClick={() => { setOpenDeleteDialog(0); onDeleteTech(tech); }} />
                            </div>
                        </Modal>
                    </div>)
            })}
        </Stack>
        <div ref={addButtonRef}  style={{marginTop: "10px", marginBottom: "10px"}}>
            <DefaultButton text="Add New Technology" onClick={onNewTech} />
        </div>
    </div>);
}