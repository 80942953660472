import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const rUrl = window.location.protocol + '//' + window.location.host;
// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/d3de0d00-8a93-4045-9b9a-e19b9fbdbe2b',
        clientId: '9bb20a56-0de7-4d9c-83fa-fa9010f116b7',
        redirectUri: rUrl
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};  

// Authentication Parameters
const authenticationParameters = {
    scopes: [
        'api://9bb20a56-0de7-4d9c-83fa-fa9010f116b7/Forms'
    ]
}

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)