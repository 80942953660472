import { Client } from "./ApiClient";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Fabric } from '@fluentui/react/lib/Fabric';
import { Pivot, PivotItem, IPivotItemProps, PivotLinkSize, PivotLinkFormat } from '@fluentui/react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import {
    IContextualMenuItemProps,
    ContextualMenuItem,
    IContextualMenuItemStyles,
    IContextualMenuStyles,
} from '@fluentui/react/lib/ContextualMenu';
import { getTheme, concatStyleSets } from '@fluentui/react/lib/Styling';

import { YourRequests } from './YourRequests';
import { PendingRequests } from './PendingRequests';
import { ApprovedRequests } from './ApprovedRequests';
import { RequestForm } from './RequestForm';
import { SpecificEmployeeRequests } from './SpecificEmployeeRequests'

import { authConfig } from './ApiClientConfig';
import { authProvider } from '../authProvider';
import { Image, IImageProps } from '@fluentui/react/lib/Image';
import { loadTheme } from '@fluentui/react';
import { NettechTheme } from '../themes/NettechTheme.js'

declare global {
    interface Window {
        ntAuthInfo: any
    }
}
//var authInfo = {
//    tenant: "netcloud.onmicrosoft.com",
//    clientId: "70a198cf-4ea9-4727-953b-b6b7acd84790",
//    resourceId: "c4c9db41-68ba-4da8-a516-26714305b5b2",
//    redirectUri: "https://TestClient",
//    token: "",

//};
//window.ntAuthInfo = authInfo;
const _commandBarItems: ICommandBarItemProps[] = [
    {
        key: 'ntTopImage',
        text: 'Networking Technologies',
        iconProps: { iconName: 'Upload' },
        onRender: () =>
            <img src="NtTopHeader.png" />

    }
];

export class OptionFrame extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        var self = this;

        loadTheme(NettechTheme);
        const theme = getTheme();

        authProvider.getAccessToken().then(function (result) {
            var tkn = result.accessToken;

            if (tkn) {
                authConfig.token = tkn;
                var client = new Client();
                client.getUserInfo().then((res) => {
                    self.setState({

                        pendingVisible: res.isManager,
                        approvedVisible: res.isHr,
                        authenticated: true,
                        name: res.name
                    });

                });
                self.processQueryString();

            }
        });



        this.state = {
            selectedKey: "1",
            pendingVisible: false,
            approvedVisible: false,
            viewId: 0,
            userType: "",
            authenticated: false,

        }


    }
    componentWillUpdate(nextProps: any, nextState: any) {
        // Added for debugging.
    }
    render() {

        if (this.state.authenticated) {
            let pivotArray: React.ReactElement<IPivotItemProps>[] = [];
            pivotArray.push(
                <PivotItem headerText="Create New Request" itemKey="1"><RequestForm onComplete={this._onComplete
                } /></PivotItem>);
            if (this.state.pendingVisible) {
                pivotArray.push(<PivotItem key="reqAppr" headerText="Requests Requiring Your Approval" itemKey="0"><PendingRequests viewId={this.state.viewId} viewHandled={this._handleComplete} /></PivotItem>);
            }
            pivotArray = pivotArray.concat(
                <PivotItem key="reqHist" headerText="Your Prior Requests" itemKey="2"><YourRequests viewId={this.state.viewId} viewHandled={this._handleComplete} /></PivotItem>
            );
            if (this.state.approvedVisible) {
                pivotArray.push(<PivotItem key="appTimeOff" headerText="Approved Time Off" itemKey="3"><ApprovedRequests viewId={this.state.viewId} viewHandled={this._handleComplete} /></PivotItem>);
            }
            if (this.state.pendingVisible) {
                pivotArray.push(
                    <PivotItem key="empReqs" headerText="Prior Employee Requests" itemKey="4"><SpecificEmployeeRequests viewId={this.state.viewId} viewHandled={this._handleComplete} /></PivotItem>);
            }
            return (

                <Fabric>
                    <Pivot selectedKey={this.state.selectedKey} defaultSelectedKey={"2"} onLinkClick={this._onLinkClick} linkSize={"large"} >
                        {pivotArray}
                    </Pivot>
                </Fabric>

            );
        }
        else {
            return (
                <Fabric>
                    <h1>Please wait while you are authenticated...</h1>
                </Fabric>
            );
        }
    }
    private processQueryString() {
        // if the id parameter exists in the querystring and is an integer, we want to
        // parse it, load the info for the request, and route to the correct view.
        var qsParams = new URLSearchParams(window.location.search);
        if (qsParams.has('id')) {
            var idStr = qsParams.get('id');
            if (idStr) {
                var id = parseInt(idStr);
                if (id > 0) {
                    var client = new Client();
                    client.getViewInfo(id).then(result => {
                        switch (result) {
                            case "Hr":
                                this.setState({ viewId: id, selectedKey: 3, userType: result });
                                break;
                            case "Submitter":
                                this.setState({ viewId: id, selectedKey: 2, userType: result });
                                break;
                            case "Approver":
                                this.setState({ viewId: id, selectedKey: 0, userType: result });
                                break;
                            default:
                                this.setState({ viewId: id, selectedKey: 1, userType: result });
                                break;

                        }
                    });
                }
            }
        }
    }
    private _onComplete = (): void => {
        this.setState({ selectedKey: 2 });
    }
    private _onLoad = (): void => {
        this.setState({ selectedKey: 1 });
    }
    private _handleComplete = (): void => {
        this.setState({ viewId: 0 });
    }

    private _onLinkClick = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>): void => {
        if (item && item.props.itemKey !== this.state.selectedKey) {
            this.setState({ selectedKey: item.props.itemKey });
        }
    }


}