import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { authProvider } from "../authProvider";
import {
    DetailsList,
    DetailsRow,
    IconButton
} from '@fluentui/react';

import { getTheme, mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { EditableCell } from './EditableCell';


const theme = getTheme();
const margin = '0 30px 20px 0';
const dragEnterClass = mergeStyles({
    backgroundColor: theme.palette.neutralLight
});
const classNames = mergeStyleSets({
    itemCell: {
        selectors: {
            '&:hover': {
                backgroundColor: theme.palette.neutralLight
            }
        }
    }
})
const controlWrapperClass = mergeStyles({
    display: 'flex',
    flexWrap: 'wrap',
});
const textFieldStyles = { root: { margin: margin } };



export const DevPriorityList = (params) => {

    
    const rootUrl = window.location.origin + "/api/devPriority/"

    const [token, setToken] = useState(null);
    const [priorities, setPriorities] = useState([]);
    const [draggedItem, setDraggedItem] = useState(null);
    const [userType, setUserType] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);

    const columns = [
        { key: "id", fieldName: "ticketId", name: "Tkt #", minWidth: 16, maxWidth: 50 },
        { key: "Order", fieldName: "order", name: "Order", minWidth: 16, maxWidth: 50 },
        { key: "Owner", fieldName: "owner", name: "Owner", minWidth: 50, maxWidth: 200, isResizeable: true },
        {
            key: "perceivedPriority",
            fieldName: "perceivedPriority",
            name: "Priority",
            minWidth: 50,
            maxWidth: 50,
            onRender: (item, index, column) => (
                <EditableCell item={item} index={index} column={column} updateItem={updateItem} />
            )
        },
        
        { key: "Title", fieldName: "title", name: "Title", minWidth: 16, maxWidth: 1000, isResizeable: true },
        { key: "status", fieldName: "status", name: "Status", minWidth: 50, maxWidth: 250, isResizeable: true },
        { key: "budgetHours", fieldName: "budgetHours", name: "Budget", minWidth: 50, maxWidth: 50, isResizeable: true},
        { key: "expand", name: "Expand", minWidth:50, MaxWidth:50, 
            onRender: (item, index)=>{
                return <IconButton iconProps={{ iconName: expandedRow === index ? 'ChevronDown' : 'ChevronRight' }} onClick={() => toggleExpand(index)} />
            }
        }
    ];

    const toggleExpand = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const renderDetails = (item) => {
        return (
            <div style={{ padding: '10px', backgroundColor: '#f3f2f1' }}>
                <p><strong>Description:</strong></p> 
                <div>{item.description}</div>
            </div>
        );
    };

    const onRenderRow = (props)=>{
        return (<div>
            <DetailsRow {...props} />
            {expandedRow === props.itemIndex && renderDetails(props.item)}
        </div>);
    };

    const updateItem = (itemId, fieldName, newValue) => {
        // verify the field is perceivedPriority
        if (fieldName === "perceivedPriority") {
            const updatedPriorities = priorities.map(item => {

                if (item.ticketId === itemId) {
                    var origValue = item[fieldName];
                    // make sure newValue is an integer
                    newValue = parseInt(newValue);
                    // No point in calling the service if the value hasn't changed.
                    if (origValue == newValue){
                        return item;
                    }
                    // if it is an integer, post and return the updated item
                    if (!isNaN(newValue)) {
                        axios.post(rootUrl + "setPerceivedPriority", { ticketId: itemId, priority: newValue }, { headers: { "Authorization": "Bearer " + token } }).catch(err => {
                            console.log(err);
                        });
                        return { ...item, [fieldName]: newValue };
                    }
                }

                return item;
            });

            setPriorities(updatedPriorities);
        }

    };
    

    const updatePriorities = useCallback((ticketId, newIndex) => {
        if (token) {
            axios.post(rootUrl + "updatePriority", { ticketId: ticketId, newIndex: newIndex }, { headers: { "Authorization": "Bearer " + token } }).then(res => {
                setPriorities(res.data);
            });
        }
    });

    const onDrop = (item, event) => {
        const droppedOverItem = item;

        if (draggedItem && droppedOverItem) {
            const droppedOverItemId = droppedOverItem.ticketId;
            const draggedItemId = draggedItem.ticketId;
            console.log(`DraggedId: ${draggedItemId} DroppedOverId: ${droppedOverItemId}`);
            const draggedItemIndex = priorities.indexOf(draggedItem);
            const droppedOverItemIndex = priorities.indexOf(droppedOverItem);

            if (draggedItemIndex != droppedOverItemIndex) {
                const newPriorities = [...priorities];
                // perform the move
                newPriorities.splice(draggedItemIndex, 1);
                newPriorities.splice(droppedOverItemIndex, 0, draggedItem);
                // update the order
                for (var i = 0; i < newPriorities.length; i++) {
                    newPriorities[i].order = i + 1;
                }
                setPriorities(newPriorities);
                updatePriorities(draggedItemId, droppedOverItemIndex);
            }
        }
        setDraggedItem(null);
    }

    // we need to useCallback for these because the userType is a state variable
    const canDrop = useCallback(() => {
        return userType == "Developer";
    }, [userType]);
    const canDrag = useCallback(() => {
        return userType == "Developer";
    }, [userType]);

    const dragDropEvents = {
        canDrop: canDrop,
        canDrag: canDrag,
        onDragEnter: () => { return dragEnterClass },
        onDrop: onDrop,
        onDragStart: (item) => { setDraggedItem(item) },
        onDragEnd: () => { setDraggedItem(null); }
    }

    // lets get our token first
    useEffect(() => {
        authProvider.getAccessToken().then((result) => {
            var tkn = result.accessToken;
            if (tkn) {
                setToken(tkn);
            }
        });
    }, []);

    const getPriorities = useCallback(() => {
        if (token) {
            axios.get(rootUrl + "priorities", { headers: { "Authorization": "Bearer " + token } }).then(res => {
                setPriorities(res.data);
            });
        }
    });
    const getUserType = useCallback(() => {
        if (token) {
            axios.get(rootUrl + "userType", { headers: { "Authorization": "Bearer " + token } }).then(res => {
                setUserType(res.data);
                //console.log(res.data);
            });
        }
    });

    useEffect(() => {
        getPriorities();
        getUserType();
    }, [token])

    return (
        <div>
            <h3>Dev Priority list</h3>
            <p>To set priority for a ticket, click on the priority field and enter a value.
                Use higher numbers for higher priority and lower numbers for lower priority.</p>
            <DetailsList
                items={priorities}
                columns={columns}
                setKey="set"
                dragDropEvents={dragDropEvents}
                className={classNames.itemCell}
                onRenderRow={onRenderRow}
                
            />
        </div>
    );
}
