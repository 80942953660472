import React, { useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react';

export const HeatMapCell = (props) => {

    const [value, setValue] = useState(props.value);
    const [techId, setTechId] = useState(props.techId);
    const [companyId, setCompanyId] = useState(props.companyId);
    const [selectedOptionId, setSelectedOptionId] = useState(props.selectedOptionId || 4);
    const [canWrite, setCanWrite] = useState(props.canWrite);
    const [onSelectionChange, setOnSelectionChange] = useState(props.onSelectionChange || (() => { }));
    const [options, setOptions] = useState(props.options? props.options.map((el) => {
        return { key: el.id, text: el.name }
    }) : []);
    //console.log(`Rendering HeatMapCell for tech ${techId} and company ${companyId} with selected option ${selectedOptionId}`);

    var color = "#ffffff";
    var textColor = "#000000";


    useEffect(() => { setValue(props.value) }, [props.value]);
    useEffect(() => { setTechId(props.techId) }, [props.techId]);
    useEffect(() => { setCompanyId(props.companyId) }, [props.companyId]);
    useEffect(() => { setCanWrite(props.canWrite); }, [props.canWrite]);
    useEffect(() => {
        setOptions(props.options ? props.options.map((el) => {
            return { key: el.id, text: el.name }
        }) : []);
    }, [props.options]);
    useEffect(() => { setOnSelectionChange(props.onSelectionChange || (() => { })) }, [props.onSelectionChange]);
    useEffect(() => {
        setSelectedOptionId(props.selectedOptionId || 4)
    }, [props.selectedOptionId]);
    
    var selectedOption = options.find(el => el.key == selectedOptionId);
    var onChange = (evt, option) => {
        if (props.onOptionChange) {
            props.onOptionChange(option.key, techId, companyId);
        }
        //console.log(`Selected option ${option.key} for tech ${techId} and company ${companyId}`);
    }

    var selectedOption = props.options.find((el) => el.id == selectedOptionId);
    var cellStyle = {
        width: "175px",
        minWidth: "175px",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
        backgroundColor: selectedOption.bgColor,
        color: selectedOption.textColor
    }
    var dropdownStyles = {
        title: { backgroundColor: selectedOption.bgColor, color: selectedOption.textColor, borderColor: selectedOption.textColor },
    }
    var result = (
        <div style={cellStyle}>
            {canWrite &&
                <Dropdown
                    options={options}
                    styles={dropdownStyles}

                    selectedKey={selectedOptionId}
                    onChange={onChange} />
            }
            {!canWrite && <div>{selectedOption.name}</div>}

        </div>)
    return result;
}