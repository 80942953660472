import React from "react";
import { AccumulationChartComponent, ChartComponent, Inject, SeriesCollectionDirective, SeriesDirective, LineSeries, ColumnSeries, Category, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend } from '@syncfusion/ej2-react-charts';
import { DetailsList, SelectionMode, Stack } from "@fluentui/react";

function calulateAccountSummary(heatMapState) {
    var startData = heatMapState.heatMapOptions.reduce((result, option) => {
        result.push({ x: option.name, y: 0, fill: option.bgColor.toLowerCase() == "#ffffff" ? "#CCCCCC" : option.bgColor })
        return result;
    }, []);

    var result = heatMapState.heatMapData.reduce((total, cust) => {
        return heatMapState.techs.reduce((techTotal, tech) => {
            var custTech = cust.techs.find((el) => el.techId == tech.id);
            var totalName;
            if (custTech) {
                totalName = custTech.value;
            }
            else {
                totalName = "Don't Know";
            }
            var totalItem = total.find((el) => el.x == totalName);
            totalItem.y++;

            return techTotal;
        }, total);
    }, startData);
    return result;
}
export const HeatMapSummary = (props) => {
    const heatMapState = props.heatMapState || [];
    const penetrationData = heatMapState.techs.map((tech, index) => {
        return { tech: tech.name, penetration: heatMapState.techPenetrations[index] };
    })
    const penPrimaryXAxis = { valueType: 'Category' };
    const pieChartData = calulateAccountSummary(heatMapState);

    const pieLegendSettings = { position: 'Bottom', visible: true};

    const detailColumns = [
        { key: "x", fieldName: "x", name: "Status" },
        { key: "y", fieldName: "y", name: "Total" }
    ]
    const percentLabelRender = (args) => {
        if (args.axis.propName == "primaryYAxis")
            args.text = `${args.text}%`;
    };
    function onRenderItemColumn(item, index, column) {

        if (column.key == "x") {
            var option = heatMapState.heatMapOptions.find((el) => el.name == item.x);
            return <div style={{ backgroundColor: option.bgColor }}>{item.x}</div>
        }
        return <span>{item[column.fieldName]}</span>
    }
    return (
        <div>
            <Stack horizontal>

                <AccumulationChartComponent id="pieChart" legendSettings={pieLegendSettings} title="Account Summary">
                    <Inject services={[AccumulationLegend]} />
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective dataSource={pieChartData} xName="x" yName="y" pointColorMapping="fill" name="Account Summary" />
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
                <ChartComponent id="barChart" axisLabelRender={percentLabelRender}
                primaryXAxis={{valueType: "Category", labelIntersectAction:"Rotate90", interval: 2}}
                primaryYAxis={{minimum: 0, maximum: 100}}
                title="Adoption by Technology">
                    <Inject services={[ColumnSeries, LineSeries, Category]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={penetrationData} xName="tech" yName="penetration" type="Column" name="Adoption by Technology" />
                    </SeriesCollectionDirective>
                </ChartComponent>
            </Stack>
            <DetailsList items={pieChartData}
                columns={detailColumns}
                compact={true}
                selectionMode={SelectionMode.none}
                onRenderItemColumn={onRenderItemColumn}
                onRender />
        </div>

    );

}